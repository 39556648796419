export default function ({ $axios }, inject) {
  const api = $axios.create({
    validateStatus: function (status) {
      // don't error on redirects or 404s
      return status >= 200 && status < 500;
    },
  });

  // Inject to context as $api
  inject("api", api);
}
