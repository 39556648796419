import { useContext } from "@nuxtjs/composition-api";
import { useAuthStore } from "../store";
import { useCheckMismatch } from "./useCheckMismatch";
import { useCookieManager } from "./useCookieManager";

export function useNewPassword() {
  const authStore = useAuthStore();
  const { $mutate, $helpers, $cookie } = useContext();

  const cookieManager = useCookieManager({ $helpers, $cookie });

  const checkMismatch = useCheckMismatch();

  async function newPasswordRequest(payload) {
    const NEW_PASSWORD_MUTATION = await import(
      "../mutations/newPasswordMutation.graphql"
    );
    return $mutate(NEW_PASSWORD_MUTATION, {
      email: payload.email,
      newPassword: payload.newPassword,
      passwordRecoveryCode: payload.passwordRecoveryCode,
      campaignsCookie: cookieManager.getCampaignsCookie("campaigns"),
    });
  }

  /**
   * Sets new password for user
   * @param {Object} payload -
   * @param {string} payload.email
   * @param {string} payload.newPassword
   * @param {string} payload.passwordRecoveryCode
   */
  return async function newPassword(payload) {
    const resp = await newPasswordRequest(payload);

    checkMismatch(resp.personId, resp.token);

    await authStore.loginWithToken(resp.token);
  };
}
