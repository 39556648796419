import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";

export default function (_ctx, inject) {
  const searchServerPayload = {
    apiKey: process.env.TYPESENSE_APIKEY, // Be sure to use an API key that only allows searches, in production
    nodes: [
      {
        host: process.env.TYPESENSE_HOST,
        port: "443",
        protocol: "https",
      },
    ],
  };
  const professionAdapted = new TypesenseInstantSearchAdapter({
    server: searchServerPayload,
    additionalSearchParameters: {
      // queryBy: 'name, description'
      query_by: "name",
      sort_by: "active_mate_count:desc",
    },
  });
  const professionTaskAdapter = new TypesenseInstantSearchAdapter({
    server: searchServerPayload,
    additionalSearchParameters: {
      query_by: "name, description, suggestion_content",
      sort_by: "active_mate_count:desc",
    },
  });
  const personAdapter = new TypesenseInstantSearchAdapter({
    server: searchServerPayload,
    additionalSearchParameters: {
      query_by:
        "name, bio, professions, cities, city_seo_name, profession_tasks",
      sort_by: "active:asc,score:desc",
      exhaustive_search: false,
      // filterBy: 'cities:=[Budapest]'
    },
  });

  const questionAdapter = new TypesenseInstantSearchAdapter({
    server: searchServerPayload,
    additionalSearchParameters: {
      query_by: "title, description",
      sort_by: "answer_count:desc",
    },
  });

  const answerAdapter = new TypesenseInstantSearchAdapter({
    server: searchServerPayload,
    additionalSearchParameters: {
      query_by: "question_title, description",
    },
  });

  const searchClients = {
    professionClient: professionAdapted.searchClient,
    professionTaskClient: professionTaskAdapter.searchClient,
    personClient: personAdapter.searchClient,
    questionClient: questionAdapter.searchClient,
    answerClient: answerAdapter.searchClient,
  };

  inject("searchClients", searchClients);
}
