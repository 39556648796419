const LIKED_PERSON_LIST = "likedPersons";
const VISITED_PERSON_LIST = "visitedProfiles";

export const state = () => ({
  likedPersonIds: [],
  visitedPersonIds: [],
});

export const getters = {
  likedPersonIds: (state) => state.likedPersonIds,
  likedPersonCount: (state) => state.likedPersonIds.length,
  visitedPersonIds: (state) => state.visitedPersonIds,
  isLikedPerson: (state) => (id) => state.likedPersonIds.includes(id),
  isVisitedPerson: (state) => (id) => state.visitedPersonIds.includes(id),
};

export const mutations = {
  setLikedPersonIds(state, payload) {
    state.likedPersonIds = payload;
  },
  setVisitedPersonIds(state, payload) {
    state.visitedPersonIds = payload;
  },
  addLikedPerson(state, id) {
    state.likedPersonIds.push(id);
    localStorage.setItem(
      LIKED_PERSON_LIST,
      JSON.stringify(state.likedPersonIds)
    );
  },
  removeLikedPerson(state, id) {
    state.likedPersonIds = state.likedPersonIds.filter(
      (personId) => personId !== id
    );
    localStorage.setItem(
      LIKED_PERSON_LIST,
      JSON.stringify(state.likedPersonIds)
    );
  },
  addVisitedPerson(state, id) {
    state.visitedPersonIds.push(id);
    localStorage.setItem(
      VISITED_PERSON_LIST,
      JSON.stringify(state.visitedPersonIds)
    );
  },
  removeVisitedPerson(state, id) {
    state.visitedPersonIds = state.visitedPersonIds.filter(
      (personId) => personId !== id
    );
    localStorage.setItem(
      VISITED_PERSON_LIST,
      JSON.stringify(state.visitedPersonIds)
    );
  },
};

export const actions = {
  initiateModule({ commit }) {
    commit(
      "setLikedPersonIds",
      JSON.parse(localStorage.getItem(LIKED_PERSON_LIST) || "[]")
    );
    commit(
      "setVisitedPersonIds",
      JSON.parse(localStorage.getItem(VISITED_PERSON_LIST) || "[]")
    );
  },
  favoritePerson({ commit }, id) {
    commit("addLikedPerson", id);
  },
  unfavoritePerson({ commit }, id) {
    commit("removeLikedPerson", id);
  },
  visitPerson({ commit }, id) {
    commit("addVisitedPerson", id);
  },
  unvisitPerson({ commit }, id) {
    commit("removeVisitedPerson", id);
  },
};
