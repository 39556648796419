import { useContext, useRoute } from "@nuxtjs/composition-api";
import { useNewTender } from "./tender/useNewTender";

export const EVENT_REQUEST_TENDER = "EVENTS_GLOBAL_REQUEST_TENDER";

export function useRequestTender() {
  const { $ab, $trackers, localePath } = useContext();
  const route = useRoute();
  const newTender = useNewTender();

  function requestTender({ source, person, city, profession, description }) {
    $trackers.trackRequestTender({
      personId: person?.id ?? null,
      personIsActive: person?.isActive ?? null,
      citySeoName: city?.seoName,
      countySeoName: city?.county?.seoName,
      professionSeoName: profession?.seoName,
      professionGroupSeoName: profession?.professionGroup?.seoName,
      source,
    });

    const shouldUseNewTender =
      $ab.isVariant("new-tender-2", "variant1") ||
      $ab.isVariant("new-tender-2", "variant2");

    if (shouldUseNewTender) {
      newTender.prefill({
        mate: person,
        description,
        city,
        profession,
      });
      newTender.open();

      return;
    }

    const path = localePath({
      name: "requestForTenderTab",
      params: {
        activeTab: 1,
      },
      query: {
        personId: person?.id,
        cityId: city?.id,
        professionId: profession?.id,
        description,
        referrer: route.query?.referrer,
      },
    });

    window.open(path);
  }

  return {
    requestTender,
  };
}
