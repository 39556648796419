<template>
  <div>
    <slot name="label" :failed="failed">
      <label
        v-if="label"
        class="text-base font-bold"
        :class="{
          'text-error': failed,
        }"
      >
        {{ label }}
      </label>
    </slot>
    <slot name="helperText" :failed="failed">
      <p
        v-if="helperText"
        class="text-base"
        :class="{
          'text-error': failed,
        }"
      >
        {{ helperText }}
      </p>
    </slot>
    <div
      class="flex flex-wrap w-full rounded border border-solid border-gray-300 hover:border-gray-400 focus-within:!border-primary focus-within:shadow-md"
      :class="{ '!bg-gray-100': _disabled }"
    >
      <div class="relative w-full flex-1">
        <IconComponent
          :pack="iconPackLeft"
          :icon="iconLeft"
          class="absolute top-1/2 -translate-y-1/2 left-0 ml-2 text-gray-300 placeholder-gray-300 w-6"
          :class="{
            '!ml-4': size === 'lg',
          }"
        />
        <template v-if="mask">
          <input
            ref="input"
            v-mask="mask"
            class="rounded py-2 box-border w-full focus:outline-none text-gray-700 placeholder-gray-300"
            :class="{
              '!pl-9': iconLeft && size !== 'lg',
              '!pl-10': iconLeft && size === 'lg',
              '!pl-2': !iconLeft,
              '!pr-9': iconRight,
              '!pr-2': !iconRight,
              'text-right': type === 'number',
              '!rounded-none': styling === 'squared',
              'text-2xl py-3': size === 'lg',
              'rounded-r-none border-r-0': unit,
              '!bg-gray-100': _disabled,
            }"
            :type="actualType"
            :placeholder="placeholder"
            :value="value"
            :disabled="_disabled"
            :step="type === 'number' ? step ?? 1 : null"
            v-bind="$attrs"
            @keypress="(event) => $emit('keypress', event)"
            @input="onInput"
            @focusout="onFocusOut"
            @blur="(event) => $emit('blur', event)"
          />
        </template>
        <template v-else>
          <input
            ref="input"
            class="rounded py-2 box-border w-full focus:outline-none text-gray-700 placeholder-gray-300"
            :class="{
              '!pl-9': iconLeft && size !== 'lg',
              '!pl-10': iconLeft && size === 'lg',
              '!pl-2': !iconLeft,
              '!pr-9': iconRight,
              '!pr-2': !iconRight,
              'text-right': type === 'number',
              '!rounded-none': styling === 'squared',
              'text-2xl py-3': size === 'lg',
              'rounded-r-none border-r-0 focus:outline-none': unit,
              '!bg-gray-100': _disabled,
            }"
            :type="actualType"
            :placeholder="placeholder"
            :value="value"
            :disabled="_disabled"
            :step="type === 'number' ? step ?? 1 : null"
            v-bind="$attrs"
            @keypress="(event) => $emit('keypress', event)"
            @input="onInput"
            @focusout="onFocusOut"
            @blur="(event) => $emit('blur', event)"
          />
        </template>
        <template v-if="type === 'password'">
          <IconComponent
            v-if="!revealed"
            class="absolute top-1/2 -translate-y-1/2 right-0 mr-2 w-6 text-info cursor-pointer"
            pack="far"
            icon="eye"
            @click.native="revealed = !revealed"
          />
          <IconComponent
            v-else
            class="absolute top-1/2 -translate-y-1/2 right-0 mr-2 w-6 text-info cursor-pointer"
            pack="far"
            icon="eye-slash"
            @click.native="revealed = !revealed"
          />
        </template>
        <template v-else>
          <IconComponent
            class="absolute top-1/2 -translate-y-1/2 right-0 mr-2 text-gray-300 placeholder-gray-300 w-6"
            :pack="iconPackRight"
            :icon="iconRight"
          />
        </template>
      </div>
      <span
        v-if="unit"
        class="flex py-2 pr-2 items-center bg-white text-gray-400 rounded-r"
        :class="{ '!bg-gray-100': disabled }"
        @click="focus"
      >
        {{ unit }}
      </span>
    </div>
    <p
      v-if="showErrorMsg"
      class="text-sm text-error"
      :class="{
        invisible: !failed,
      }"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { inject } from "@nuxtjs/composition-api";

export default {
  directives: { mask },
  props: {
    value: {
      type: [String, Boolean, Number],
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    validationState: {
      type: Object,
      required: false,
      default: () => {},
    },
    type: {
      type: String,
      default: "text",
      // TODO add more as needed
      validator: (val) =>
        [
          "text",
          "email",
          "number",
          "tel",
          "checkbox",
          "password",
          "date",
        ].includes(val),
    },
    styling: {
      type: String,
      default: "rounded",
      validator: (val) => ["rounded", "squared"].includes(val),
    },
    iconLeft: {
      type: String,
      default: "",
    },
    iconPackLeft: {
      type: String,
      default: "fas",
    },
    iconRight: {
      type: String,
      default: "",
    },
    iconPackRight: {
      type: String,
      default: "fas",
    },
    placeholder: {
      type: String,
      default: "",
    },
    mask: {
      type: [String, Array],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: null,
    },
    precision: {
      type: Number,
      default: 2,
    },
    showErrorMsg: {
      type: Boolean,
      default: true,
    },
    reserveErrorSpace: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "md",
      validator: function (val) {
        return ["sm", "md", "lg"].includes(val);
      },
    },
    unit: {
      type: String,
      default: null,
    },
    helperText: {
      type: String,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { disabled: formDisabled } = inject("form", { disabled: false });
    return {
      formDisabled,
    };
  },
  data() {
    return {
      revealed: false,
    };
  },
  computed: {
    _disabled() {
      return this.disabled || this.formDisabled;
    },
    hasIconRight() {
      return this.failed || !!this.iconRight;
    },
    errorMessage() {
      return (
        this.validationState?.$errors?.[0]?.$message ??
        (this.reserveErrorSpace
          ? "placeholder so height is correctly computed"
          : "")
      );
    },
    failed() {
      return this.validationState?.$error;
    },
    actualType() {
      return this.type === "password" && this.revealed ? "text" : this.type;
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.input.focus();
    }
  },
  methods: {
    onInput(event) {
      const value = event.target.value;
      this.$emit("input", value);
    },
    focus() {
      this.$refs.input.focus();
    },
    onFocusOut(event) {
      if (this.type === "number") {
        const parsedValue = Number(event.target.value).toFixed(this.precision);

        this.$emit("input", Number(parsedValue));
      }
      this.$emit("focusout", event);
    },
  },
};
</script>

<style>
/* remove up-down arrows for number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
