import { unref } from "@nuxtjs/composition-api";
const CAPITAL_CITY_ID = "2";

export default function useCapital() {
  const isCapital = (value) => {
    return unref(value)?.id === CAPITAL_CITY_ID;
  };

  return { isCapital };
}
