export default (ctx, inject) => {
  // lodash templating
  const brand = {"name":"JóSzaki","about":"JóSzakiról","slogan":"A közösség ajánlásával","webpage":"JóSzaki.hu","logo":{"part1":"JÓ","part2":"SZAKI"},"fromMate":"JóSzakis szakemberünktől!","since":"2010","themeColor":"#337ab7","company":{"name":"JóSzaki Kft.","address":"1111 Budapest, Lágymányosi utca 12. Fsz. 2. ajtó ","registrationAuthority":"Fővárosi Törvényszék Cégbírósága","registrationNumber":"01-09-328629","taxNumber":"26495248-2-42","hosting":"Google LLC (1600 Amphitheatre Parkway Mountain View, CA 94043, USA)"}};

  const brandName =  "joszaki";
  const isSuperprofikCz = brandName === "superprofik-cz";
  const isBerufExperten = brandName === "berufexperten-de";
  const isJoszaki = brandName === "joszaki";

  inject('brand', brand);
  inject('branding', {
    name: brandName,
    isSuperprofikCz,
    isBerufExperten,
    isJoszaki
  })
};