<template>
  <Icon
    style="font-size: inherit"
    :icon="`${iconPack}:${iconData}`"
    :inline="false"
  />
</template>

<script>
import { Icon } from "@iconify/vue2/dist/offline";

const iconMapping = {
  fa: "fa-solid",
  fas: "fa-solid",
  far: "fa-regular",
  fab: "fa-brands",
  flags: "flags",
};

export default {
  components: {
    Icon,
  },
  inheritAttrs: true,
  props: {
    // find available icons in '~plugins/iconify.js', use addIcon if new one is needed
    icon: {
      type: [Array, String],
      required: true,
    },
    pack: {
      type: String,
      default: "fas",
    },
    size: {
      type: String,
      default: null,
    },
  },
  computed: {
    iconPack() {
      if (Array.isArray(this.icon) && this.icon.length > 0) {
        return iconMapping[this.icon[0]];
      }
      return iconMapping[this.pack];
    },
    iconData() {
      if (Array.isArray(this.icon) && this.icon.length > 0) {
        return this.icon[1];
      }
      return this.icon;
    },
  },
};
</script>
