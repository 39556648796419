/**
 * if array includes value remove it else add it
 */
export const arrayToggle = (array, value) => {
  if (array.includes(value)) {
    return array.filter((x) => x !== value);
  } else {
    return [...array, value];
  }
};

/**
 * Symmetrical difference of 2 arrays
 * @param {*} arrA
 * @param {*} arrB
 *
 * return elements that are not present in both arrays
 */
export const arraySimDiff = (arrA, arrB) => {
  return arrA
    .filter((x) => !arrB.includes(x))
    .concat(arrB.filter((x) => !arrA.includes(x)));
};
