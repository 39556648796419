<template>
  <div class="InputWrapper" :class="{ '!bg-gray-100': disabled }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.InputWrapper {
  @apply bg-white flex flex-row flex-nowrap items-center w-full border-gray-300 hover:border-gray-400 focus-visible:shadow-md focus-within:!border-primary rounded border border-solid gap-2 py-2 px-2;
}

.InputWrapper input {
  @apply text-gray-700 placeholder-gray-300 w-full;
}

.lg .InputWrapper {
  @apply !text-2xl !py-3 !gap-4 !px-4;
}
</style>
