<template>
  <b-loading
    :active="active"
    :can-cancel="canCancel"
    :is-full-page="isFullPage"
  />
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    canCancel: {
      type: Boolean,
      default: false,
    },
    isFullPage: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
