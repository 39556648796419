<template>
  <div class="relative" style="display: contents">
    <JoszakiButton v-bind="$attrs" @click="onClick">
      <div
        v-show="isDropdownOpen"
        ref="dropdown"
        class="absolute top-full min-w-fit bg-white rounded-md z-10 border border-gray-300 shadow-md"
        :class="{
          'left-0': leftAligned,
          'right-0': !leftAligned,
        }"
      >
        <div
          v-for="(item, index) in items"
          :key="index"
          class="max-md:py-2 md:py-1 pl-3 pr-2 cursor-pointer hover:bg-gray-100 text-sm text-black text-left whitespace-nowrap"
          @click="onItemSelected(item)"
        >
          {{ item[labelKey] }}
        </div>
      </div>
      <slot />
    </JoszakiButton>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    labelKey: {
      type: String,
      default: "label",
    },
    valueKey: {
      type: String,
      default: "value",
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    leftAligned: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  mounted() {
    document.addEventListener("click", this.outsideClickHandler);
  },
  destroyed() {
    document.removeEventListener("click", this.outsideClickHandler);
  },
  methods: {
    onClick(e) {
      if (this.disabled || this.loading) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if (this.href) {
        e.preventDefault();
        e.stopPropagation();
        location.href = this.href;
      }
      if (!this.$refs.dropdown.contains(e.target)) {
        this.isDropdownOpen = !this.isDropdownOpen;
      }
    },
    onItemSelected(item) {
      if (this.closeOnSelect) {
        this.isDropdownOpen = false;
      }
      this.$emit("itemSelected", item);
    },
    outsideClickHandler(e) {
      if (!this.$el.contains(e.target) && this.isDropdownOpen) {
        this.isDropdownOpen = false;
      }
    },
  },
};
</script>
