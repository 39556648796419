import {
  calculateStepResultFormulasForEntity,
  groupSum,
} from "@/helpers/calculator";

export const STEP_TYPE_SELECT = "select";
export const STEP_TYPE_INPUT = "input";
export const STEP_TYPE_RESULT = "result";

const createEntityFromInputs = (inputs) => {
  return (inputs ?? []).reduce(
    (acc, input) => ({
      ...acc,
      [input.variableName]: input?.properties?.default ?? 0,
    }),
    {}
  );
};

const defaultState = {
  isInitialized: false,
  error: null,

  description: null,
  relatedContent: null,
  professionTask: null,
  stepSeoNames: [],
  stepsBySeoName: {},

  isPopupShown: false,
  currentStepIndex: -1,
};

export const state = () => ({
  ...defaultState,
});

export const getters = {
  professionTask: (state) => state.professionTask,
  professionTaskSeoName: (state) => state.professionTask?.seoName ?? null,
  profession: (state) => state.professionTask?.professions?.[0] ?? null,
  description: (state) => state.description,
  relatedContent: (state) => state.relatedContent,
  currentStepIndex: (state) => state.currentStepIndex,
  currentStepSeoName: (state) => state.stepSeoNames[state.currentStepIndex],
  currentStep: (state, getters) =>
    state.stepsBySeoName[getters.currentStepSeoName],
  currentStepOptions: (_state, getters) => {
    const currentStepSeoName = getters.currentStepSeoName;
    return getters.filteredOptionsByStepSeoName(currentStepSeoName);
  },
  filteredOptionsByStepSeoName: (state, getters) => (stepSeoName) => {
    const allOptions = state.stepsBySeoName[stepSeoName]?.options ?? [];
    const stepSeoNames = state?.stepSeoNames ?? [];
    const stepIndex = stepSeoNames.indexOf(stepSeoName);
    const previousStepSeoNames = stepSeoNames.slice(0, Math.max(0, stepIndex));
    const previousVariables = previousStepSeoNames
      .map(
        (stepSeoName) =>
          getters.selectedOptionForStepSeoName(stepSeoName)?.variables ?? {}
      )
      .reduce((prev, current) => ({ ...prev, ...current }), {});

    const filteredOptions = allOptions.filter((option) => {
      const conditions = option?.variables?.conditions ?? {};
      for (const condition in conditions) {
        if (previousVariables[condition] !== conditions[condition]) {
          return false;
        }
      }
      return true;
    });

    return filteredOptions;
  },
  stepSeoNames: (state) => state.stepSeoNames,
  stepsCount: (state) => state.stepSeoNames?.length ?? 0,
  prevStepSeoName: (state, getters) =>
    getters.currentStepIndex > 0
      ? state.stepSeoNames[getters.currentStepIndex - 1]
      : null,
  nextStepSeoName: (state, getters) =>
    getters.currentStepIndex < getters.stepsCount - 1
      ? state.stepSeoNames[getters.currentStepIndex + 1]
      : null,
  stepNames: (state) =>
    state.stepSeoNames.map((stepSeoName) => {
      const step = state.stepsBySeoName[stepSeoName] ?? null;
      if (!step) {
        return null;
      }
      return {
        seoName: step.seoName,
        name: step.name,
      };
    }),
  error: (state) => state.error,
  isInitialized: (state) => state.isInitialized,
  isPopupShown: (state) => state.isPopupShown,
  entityByIndex: (state) => (stepSeoName, entityIndex) => {
    const step = state.stepsBySeoName[stepSeoName] ?? null;

    if (!step || !step.entities || !step.entities[entityIndex]) {
      return null;
    }

    return state.stepsBySeoName[stepSeoName].entities[entityIndex];
  },
  selectedOptionForStepSeoName: (state) => (stepSeoName) => {
    const step = state.stepsBySeoName[stepSeoName] ?? null;

    if (!step || !step.options || !step.selectedOptionId) {
      return null;
    }

    const selectedOptions = step.options.filter(
      ({ id }) => id === step.selectedOptionId
    );
    if (selectedOptions.length !== 1) {
      throw new Error("Non unique selected option");
    }

    return selectedOptions[0];
  },
  combinedDescriptions: (state) => {
    return (state.stepSeoNames ?? [])
      .map(
        (stepSeoName, index) =>
          state.stepsBySeoName[stepSeoName].description?.replace(
            "## ",
            `## ${index + 1}. `
          ) ?? ""
      )
      .join("\n\n");
  },
  selectedOptionNames: (state, getters) => {
    return (state.stepSeoNames ?? [])
      .filter(
        (stepSeoName) =>
          state.stepsBySeoName[stepSeoName].type === STEP_TYPE_SELECT
      )
      .map(
        (stepSeoName) => getters.selectedOptionForStepSeoName(stepSeoName).label
      );
  },
};

export const mutations = {
  clearState(state) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state = {
      ...defaultState,
    };
  },
  setProfessionTask(state, professionTask) {
    state.professionTask = professionTask;
  },
  setDescription(state, description) {
    state.description = description;
  },
  setRelatedContent(state, relatedContent) {
    state.relatedContent = relatedContent;
  },
  setCurrentStepById(state, stepSeoName) {
    state.currentStepIndex = (state.stepSeoNames ?? []).findIndex(
      (id) => id === stepSeoName
    );
    state.isPopupShown = false;
  },
  setSteps(state, steps) {
    state.stepSeoNames = steps.map((step) => step.seoName);
    state.stepsBySeoName = steps.reduce(
      (acc, step) => ({
        ...acc,
        [step.seoName]: {
          ...step,
          selectedOptionId:
            step.type === STEP_TYPE_SELECT && step.options?.length > 0
              ? step.options[0].id
              : null,
          entities:
            step.type === STEP_TYPE_INPUT
              ? [createEntityFromInputs(step.inputs)]
              : [],
          results: null,
        },
      }),
      {}
    );
  },
  setIsInitialized(state, isInitialized) {
    state.isInitialized = isInitialized;
  },
  setError(state, error) {
    state.error = error;
  },
  setSelectedOptionById(state, { stepSeoName, optionId }) {
    const step = state.stepsBySeoName[stepSeoName];
    if (!step || step.type !== STEP_TYPE_SELECT) {
      return;
    }

    step.selectedOptionId = optionId;
  },
  setIsPopupShown(state, isPopupShown) {
    state.isPopupShown = isPopupShown;
  },
  addEntity(state, { stepSeoName }) {
    const step = state.stepsBySeoName[stepSeoName];
    if (!step || step.type !== STEP_TYPE_INPUT) {
      return;
    }

    const entity = createEntityFromInputs(step.inputs);

    state.stepsBySeoName[stepSeoName].entities = [
      ...(state.stepsBySeoName[stepSeoName].entities ?? []),
      entity,
    ];
  },
  removeEntityAt(state, { stepSeoName, entityIndex }) {
    state.stepsBySeoName[stepSeoName].entities = state.stepsBySeoName[
      stepSeoName
    ].entities.filter((_value, index) => index !== entityIndex);
  },
  setEntityValue(state, { stepSeoName, entityIndex, variableName, value }) {
    state.stepsBySeoName[stepSeoName].entities[entityIndex][variableName] =
      value;
  },
  setStepResults(state, { stepSeoName, entities, totals }) {
    const step = state.stepsBySeoName[stepSeoName];
    if (!step || step.type !== STEP_TYPE_RESULT) {
      return;
    }

    step.results = {
      entities,
      totals,
    };
  },
};

export const actions = {
  setPriceCalculator(context, priceCalculator) {
    if (!priceCalculator) {
      context.commit("clearState");
      context.commit("setError", true);
    } else {
      context.commit("setProfessionTask", priceCalculator.professionTask);
      context.commit("setSteps", priceCalculator.steps);
      context.commit("setDescription", priceCalculator.description);
      context.commit("setRelatedContent", priceCalculator.relatedContent);
      context.commit("setCurrentStepById", context.getters.stepSeoNames[0]);
      context.commit("setIsInitialized", true);
      context.commit("setError", null);
    }
  },
  async setStepBySeoName(context, stepSeoName) {
    const stepSeoNames = context.getters.stepSeoNames;

    if (!stepSeoNames) {
      return;
    }

    const actualStepSeoName = stepSeoNames.includes(stepSeoName)
      ? stepSeoName
      : stepSeoNames[0];

    context.commit("setCurrentStepById", actualStepSeoName);
    if (
      context.state.stepsBySeoName[actualStepSeoName]?.type === STEP_TYPE_RESULT
    ) {
      await context.dispatch("calculateResults", actualStepSeoName);
    }
  },
  setSelectedOptionById(context, { stepSeoName, optionId }) {
    const step = context.state.stepsBySeoName[stepSeoName];
    if (!step || step.type !== STEP_TYPE_SELECT) {
      return;
    }

    context.commit("setSelectedOptionById", { stepSeoName, optionId });

    const stepIndex = context.state.stepSeoNames.indexOf(stepSeoName);
    const laterStepSeoNames = context.state.stepSeoNames.slice(stepIndex + 1);
    for (const laterStepSeoName of laterStepSeoNames) {
      const laterStep = context.state.stepsBySeoName[laterStepSeoName];
      if (laterStep.type !== STEP_TYPE_SELECT) {
        continue;
      }
      const filteredOptionIds = context.getters
        .filteredOptionsByStepSeoName(laterStepSeoName)
        .map((option) => option.id);
      const currentSelectedId =
        context.getters.selectedOptionForStepSeoName(laterStepSeoName)?.id;
      if (filteredOptionIds.includes(currentSelectedId)) {
        continue;
      }
      context.commit("setSelectedOptionById", {
        stepSeoName: laterStepSeoName,
        optionId: filteredOptionIds[0],
      });
    }
  },
  calculateResults(context, stepSeoName) {
    const resultStep = context.state.stepsBySeoName[stepSeoName];
    const stepSeoNames = context.state.stepSeoNames;

    const stepVariables = stepSeoNames.map((stepSeoName) => {
      const step = context.state.stepsBySeoName[stepSeoName];

      if (step.type === STEP_TYPE_INPUT) {
        const variableName = `${step.entityName}Count`;
        return {
          [variableName]: step.entities.length,
        };
      }

      if (step.type === STEP_TYPE_SELECT) {
        const selectedOption =
          context.getters.selectedOptionForStepSeoName(stepSeoName);
        if (!selectedOption) {
          return {};
        }
        return {
          ...selectedOption.variables,
        };
      }

      return {};
    });

    const commonVariables = stepVariables.reduce((acc, curr) =>
      Object.assign(acc, curr)
    );

    const stepResultEntities = stepSeoNames.map((stepSeoName) => {
      const step = context.state.stepsBySeoName[stepSeoName];
      if (step.type !== STEP_TYPE_INPUT) {
        return [];
      }

      return step.entities.map((entity, index) =>
        calculateStepResultFormulasForEntity(
          entity,
          index,
          commonVariables,
          step.entityName,
          resultStep.formulas
        )
      );
    });

    const resultEntities = [].concat(...stepResultEntities);

    const entityRows = resultEntities.map((entities) => entities.rows).flat();
    const totalsRows = groupSum(entityRows);

    const totals = {
      label: this.$i18n.t("priceCalculator.resultBox.totalsBoxLabel"),
      rows: totalsRows,
    };

    context.commit("setStepResults", {
      stepSeoName,
      entities: resultEntities,
      totals,
    });
  },
};
