/**
 * Evaluates an expression in Postfix notation which may contain variables.
 * @param expression The expression in Postfix notation (Reverse Polish notation), e.g. 10 Width Height * +.
 * Only basic operators (+,-,*,/) are supported.
 * @param variables The optional object that contains the values for variables
 * @returns {Number} The calculated value of the expression
 */
export function evaluatePostfixNotationExpression(expression, variables = {}) {
  if (!expression) {
    return 0;
  }

  const tokens = expression.split(" ");
  const stack = [];

  const operators = "+-*/><≤&|";

  for (const token of tokens) {
    const number = Number(token);
    if (!isNaN(number)) {
      stack.push(number);
      continue;
    }

    if (operators.includes(token)) {
      const operand2 = stack.pop();
      const operand1 = stack.pop();

      switch (token) {
        case "+":
          stack.push(operand1 + operand2);
          break;
        case "-":
          stack.push(operand1 - operand2);
          break;
        case "*":
          stack.push(operand1 * operand2);
          break;
        case "/":
          stack.push(operand1 / operand2);
          break;
        case ">":
          stack.push(operand1 > operand2 ? 1 : 0);
          break;
        case "<":
          stack.push(operand1 < operand2 ? 1 : 0);
          break;
        case "≤":
          stack.push(operand1 <= operand2 ? 1 : 0);
          break;
        case "&":
          stack.push(!!operand1 && !!operand2 ? 1 : 0);
          break;
        case "|":
          stack.push(!!operand1 || !!operand2 ? 1 : 0);
          break;
      }
      continue;
    }

    stack.push(variables[token] ? Number(variables[token]) : 0);
  }

  if (stack.length > 1) {
    throw new Error(
      "Expression not complete, needs more operands:" + expression
    );
  }

  return stack[0];
}

/**
 * Converts an expression in conventional Infix Notation to Postfix notation (Reverse Polish notation)
 * using the Shunting yard algorithm (https://en.wikipedia.org/wiki/Shunting_yard_algorithm).
 * @param expression The expression in conventional Infix Notation, e.g. 10 + (Width * Height).
 *        Only basic operators (+,-,*,/) are supported. Round brackets can be used.
 *        Separate expressions from operators with space.
 * @returns {string}  The expression in Postfix notation, eg. 10 Width Height * +
 */
export const toPostfixNotationExpression = (expression) => {
  if (!expression) {
    return expression;
  }

  const precedenceMap = {
    "*": 3,
    "&": 3,
    "/": 3,
    "+": 2,
    "|": 2,
    "-": 2,
    ">": 1,
    "<": 1,
    "≤": 1,
  };
  const operators = Object.keys(precedenceMap);
  const stack = [];
  const output = [];
  const expressionTokens = expression
    .replace(/\(/g, "( ")
    .replace(/\)/g, " )")
    .split(" ");

  const assert = (predicate) => {
    if (predicate) {
      return;
    }
    throw new Error("Assertion failed due to invalid token");
  };

  for (const token of expressionTokens) {
    if (operators.includes(token)) {
      const currentOperator = token;
      let prevOperator = stack.at(-1);

      while (
        !!prevOperator &&
        prevOperator !== "(" &&
        precedenceMap[prevOperator] >= precedenceMap[currentOperator]
      ) {
        output.push(stack.pop());
        prevOperator = stack.at(-1);
      }
      stack.push(currentOperator);
      continue;
    }

    if (token === "(") {
      stack.push(token);
      continue;
    }

    if (token === ")") {
      let topOfStack = stack.at(-1);
      while (topOfStack !== "(") {
        assert(stack.length !== 0);
        output.push(stack.pop());
        topOfStack = stack.at(-1);
      }
      assert(stack.at(-1) === "(");
      stack.pop();
      continue;
    }

    output.push(token);
  }

  while (stack.length !== 0) {
    assert(stack.at(-1) !== "(");
    output.push(stack.pop());
  }

  return output.join(" ");
};

function calculateAndStoreFormula(formula, variables, resultRows, rows = []) {
  const postfixExpression = toPostfixNotationExpression(formula.formula);
  const value = evaluatePostfixNotationExpression(postfixExpression, variables);

  const actualValue =
    formula.unit === process.env.CURRENCY
      ? Math.round(value / 100) * 100
      : value;

  variables[formula.variableName] = actualValue;
  resultRows.push({
    variableName: formula.variableName,
    label: formula.label,
    value: actualValue,
    unit: formula.unit,
    rows,
  });
}

export function calculateStepResultFormulasForEntity(
  entity,
  entityIndex,
  commonVariables,
  entityName,
  formulas
) {
  const entityVariables = {
    ...commonVariables,
    ...entity,
  };
  const resultEntity = {
    label: `${entityIndex + 1}. ${entityName}`,
    rows: [],
  };

  formulas.forEach((formula) => {
    if (formula.mainFormulaId) {
      return;
    }

    const subRows = [];
    const subFormulas = formulas.filter((f) => f.mainFormulaId === formula.id);
    subFormulas.forEach((subFormula) =>
      calculateAndStoreFormula(subFormula, entityVariables, subRows)
    );

    calculateAndStoreFormula(
      formula,
      entityVariables,
      resultEntity.rows,
      subRows
    );
  });

  return resultEntity;
}

/**
 * Groups and sums rows by variableName for a grand total
 * @param rows
 * @returns Array The rows of the grand total
 */
export function groupSum(rows) {
  return Object.values(
    rows.reduce((result, current) => {
      if (result[current.variableName]) {
        return {
          ...result,
          [current.variableName]: {
            ...result[current.variableName],
            value: result[current.variableName].value + current.value,
            rows: result[current.variableName].rows.concat(current?.rows ?? []),
          },
        };
      } else {
        return {
          ...result,
          [current.variableName]: current,
        };
      }
    }, {})
  ).map((row) => ({
    ...row,
    rows: groupSum(row?.rows ?? []),
  }));
}
