let context = null;

const saveUtmParameters = function (source, medium, campaign, content, term) {
  let campaigns = JSON.parse(context.$cookie.get("campaigns") || null);

  if (!campaigns) {
    campaigns = [];
  }

  if (
    campaigns.length === 0 ||
    campaigns[campaigns.length - 1].source !== source ||
    campaigns[campaigns.length - 1].medium !== medium ||
    campaigns[campaigns.length - 1].campaign !== campaign ||
    campaigns[campaigns.length - 1].content !== content ||
    campaigns[campaigns.length - 1].term !== term
  ) {
    campaigns.push({
      timestamp: new Date().getTime(),
      source,
      medium,
      campaign,
      content,
      term,
    });

    let domain = "." + context.$helpers.getMainDomain();

    if (domain === ".localhost") {
      domain = "";
    }

    context.$cookie.set("campaigns", JSON.stringify(campaigns), {
      maxAge: 365,
      path: "/",
      domain,
    });
  }
};

const checkUtmParameters = (route) => {
  const source = route?.query?.utm_source ?? "";
  const medium = route?.query?.utm_medium ?? "";
  const campaign = route?.query?.utm_campaign ?? "";
  const content = route?.query?.utm_content ?? "";
  const term = route?.query?.utm_term ?? "";

  if (source || medium || campaign || content || term) {
    saveUtmParameters(source, medium, campaign, content, term);
  }
};

export default (ctx) => {
  window.onNuxtReady(() => {
    context = ctx;
    checkUtmParameters(ctx.route);
  });
};
