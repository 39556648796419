var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"QuickOffer w-full grid",class:{
    'bg-white rounded-lg shadow-md': _vm.version === 'white',
    'bg-typo-primary/5 rounded-md shadow-none': _vm.version === 'grey',
    'bg-primary rounded-md shadow-none': _vm.version === 'blue',
    'justify-items-center': _vm.textInput,
    'gap-3 p-5': _vm.size === 'lg',
    'gap-1 p-2': _vm.size === 'sm',
  }},[_c('span',{staticClass:"font-bold text-2xl",class:{
      'text-primary': _vm.version === 'white',
      'text-typo-primary': _vm.version === 'grey',
      'text-white': _vm.version === 'blue',
    }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('span',{class:{
      'text-xl font-bold text-primary': _vm.version === 'white',
      'text-sm font-semibold text-typo-primary': _vm.version === 'grey',
      'text-sm font-bold text-white': _vm.version === 'blue',
    }},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]),_c('TenderSteps',{staticClass:"max-w-lg max-h-20 mb-5",attrs:{"is-white":_vm.version === 'blue'}}),(_vm.textInput)?_c('div',{staticClass:"flex flex-col w-full text-center",class:{
      'text-typo-primary': _vm.version === 'grey',
      'text-white': _vm.version === 'blue',
      'my-4': _vm.size === 'lg',
      'my-1': _vm.size === 'sm',
    }},[_c('p',{staticClass:"text-base font-bold mb-1",class:{ 'text-white': _vm.version === 'blue' }},[_vm._v(" "+_vm._s(_vm.$t("quickOffer.inputLabel"))+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.tenderText),expression:"tenderText"}],staticClass:"w-full h-20 min-h-[20px] shadow-inner border border-solid border-gray-400 p-3 focus-visible:border-primary focus-visible:outline-none placeholder:text-gray-400 rounded-md",class:{
        'outline-primary': _vm.version === 'white',
        'bg-white/20 border border-solid border-black/20 outline-none shadow-none font-semibold':
          _vm.version === 'grey',
        'outline-white': _vm.version === 'blue',
      },attrs:{"placeholder":_vm.textareaPlaceholder},domProps:{"value":(_vm.tenderText)},on:{"focus":function($event){return _vm.$trackers.trackClick('QuickOfferInput')},"input":function($event){if($event.target.composing)return;_vm.tenderText=$event.target.value}}})]):_vm._e(),_c('JoszakiButton',{staticClass:"w-full",attrs:{"inverted":_vm.version === 'blue'},on:{"click":_vm.requestTender}},[_vm._v(" "+_vm._s(_vm.textOfButton)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }