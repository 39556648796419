<template>
  <div class="flex flex-col w-full h-full @container">
    <div class="flex-0">
      <svg
        id="express"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1000 100"
        style="enable-background: new 0 0 1000 100"
        xml:space="preserve"
      >
        <style type="text/css">
          .st0 {
            fill: #d4d4d4;
          }
          .st1 {
            fill: #337ab7;
          }
          .white {
            fill: white;
          }
        </style>
        <path
          class="st0"
          d="M824.4,19.4C809.9,19.4,798,30.1,796,44H213v8h583c1.9,13.9,13.9,24.6,28.3,24.6c15.8,0,28.6-12.8,28.6-28.6
                    C853,32.2,840.2,19.4,824.4,19.4z M834.7,60.7c-0.6,0.5-1.4,0.7-2.6,0.7h-14.6c-1,0-1.7-0.3-2.3-0.9c-0.6-0.6-0.8-1.3-0.8-2.2
                    c0-1.1,0.5-2.2,1.4-3.1l8.5-8.8c1.8-1.8,2.6-3.5,2.6-5.1c0-0.9-0.3-1.6-0.8-2.1c-0.5-0.5-1.3-0.7-2.2-0.7c-0.8,0-1.5,0.1-2.1,0.3
                    c-0.7,0.2-1.4,0.6-2.3,1l-0.8,0.4c-0.1,0.1-0.3,0.2-0.6,0.3s-0.6,0.3-0.8,0.4c-0.2,0.1-0.5,0.1-0.8,0.1c-0.7,0-1.2-0.3-1.7-0.9
                    c-0.5-0.6-0.7-1.3-0.7-2.1c0-0.6,0.1-1.1,0.3-1.4s0.5-0.7,1-1c1.3-0.9,2.7-1.5,4.2-2s3.1-0.7,4.6-0.7c2,0,3.7,0.3,5.2,1
                    c1.5,0.7,2.6,1.6,3.5,2.8c0.8,1.2,1.2,2.6,1.2,4.2c0,1.6-0.3,3.1-1,4.5c-0.7,1.3-1.8,2.8-3.3,4.5l-5.7,5.8h8c1.2,0,2,0.2,2.6,0.7
                    c0.6,0.5,0.9,1.2,0.9,2.2C835.5,59.5,835.2,60.2,834.7,60.7z"
        />
        <path
          class="st1"
          :class="{ white: isWhite }"
          d="M290.4,40.5h-90.1c-3.3-12.2-14.4-21.1-27.6-21.1C156.8,19.4,144,32.2,144,48c0,15.8,12.8,28.6,28.6,28.6
                    c13.2,0,24.3-9,27.6-21.1h90.1c4.1,0,7.5-3.4,7.5-7.5S294.5,40.5,290.4,40.5z M180.3,61.7h-13.9c-2.3,0-3.5-1-3.5-3
                    c0-1,0.3-1.7,0.8-2.2s1.4-0.7,2.6-0.7h3.2V41.4l-3.2,1.9c-0.5,0.3-0.9,0.4-1.4,0.4c-0.7,0-1.4-0.3-1.9-1c-0.5-0.7-0.8-1.4-0.8-2.2
                    c0-1.1,0.5-1.9,1.4-2.5l6.4-3.7c1.2-0.7,2.3-1,3.3-1c1.1,0,1.9,0.3,2.6,1c0.7,0.6,1,1.5,1,2.6v19h3.3c1.2,0,2.1,0.2,2.6,0.7
                    s0.8,1.2,0.8,2.2C183.8,60.7,182.6,61.7,180.3,61.7z"
        />
      </svg>
    </div>
    <div class="flex justify-between items-center px-7 lg:px-10 2xl:px-14">
      <div class="@[250px]:text-xs @[350px]:text-lg">
        <div class="font-bold">{{ $t("tenderSteps.step1.describe") }}</div>
        <div>{{ $t("tenderSteps.step1.theTask") }}</div>
      </div>
      <div class="@[250px]:text-xs @[350px]:text-lg text-right">
        <div>
          {{ $t("tenderSteps.step2.chooseFrom") }}
        </div>
        <div class="font-bold">
          {{ $t("tenderSteps.step2.fromProfessionals") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isWhite: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
