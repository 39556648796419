<template>
  <div class="flex justify-center gap-2">
    <PaginationButton
      v-if="showFirstLast"
      :disabled="paginationInfo.current === paginationInfo.first"
      :url="getPageUrl(paginationInfo.first)"
      @click="selectPage(paginationInfo.first)"
    >
      <IconComponent icon="angle-double-left" />
    </PaginationButton>

    <PaginationButton
      :disabled="!paginationInfo.hasPrevious"
      :url="getPageUrl(paginationInfo.current - 1)"
      @click="selectPage(paginationInfo.current - 1)"
    >
      <IconComponent icon="angle-left" />
    </PaginationButton>

    <PaginationButton
      v-for="index in getIndexes"
      :key="index"
      :active="index === paginationInfo.current"
      :url="getPageUrl(index)"
      :class="{
        'block md:hidden':
          paginationInfo.first === paginationInfo.current ||
          paginationInfo.current === paginationInfo.last
            ? Math.abs(paginationInfo.current - index) > increment
            : Math.abs(paginationInfo.current - index) > increment - 1, // On desktop show one less
      }"
      @click="selectPage(index)"
    >
      {{ index }}
    </PaginationButton>
    <PaginationButton
      :disabled="!paginationInfo.hasNext"
      :url="getPageUrl(paginationInfo.current + 1)"
      @click="selectPage(paginationInfo.current + 1)"
    >
      <IconComponent icon="angle-right" />
    </PaginationButton>

    <PaginationButton
      v-if="showFirstLast"
      :disabled="paginationInfo.current === paginationInfo.last"
      :url="getPageUrl(paginationInfo.last)"
      @click="selectPage(paginationInfo.last)"
    >
      <IconComponent icon="angle-double-right" />
    </PaginationButton>
  </div>
</template>

<script>
import PaginationButton from "~/components/_refactored/common/PaginationButton.vue";

export default {
  components: {
    PaginationButton,
  },
  props: {
    paginationInfo: {
      type: Object,
      required: true,
    },
    increment: {
      type: Number,
      default: 2,
    },
    showFirstLast: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getCurrentPageRangeTwo() {
      let start = this.paginationInfo.current - this.increment;
      let end = this.paginationInfo.current + this.increment;

      if (start < 1) {
        start = 1;
        end += 1;
        if (end > this.paginationInfo.last) {
          end = this.paginationInfo.last;
        }
      }
      if (end > this.paginationInfo.last) {
        end = this.paginationInfo.last;
        start -= 1;
        if (start < 1) {
          start = 1;
        }
      }

      return { start, end };
    },
    start() {
      return this.getCurrentPageRangeTwo.start;
    },
    end() {
      return this.getCurrentPageRangeTwo.end;
    },
    getIndexes() {
      return this.$helpers.arrayRange(this.start, this.end, 1);
    },
  },
  methods: {
    selectPage(page) {
      this.$trackers.trackClick("PaginationButton", {
        page,
      });

      // this.$trackers.trackEvent({
      //   title: "Pagination Select Page",
      //   data: {
      //     page,
      //   },
      // });

      this.$emit("pageChange", page);
    },
    getPageUrl(page) {
      const newRoute = {
        ...this.$route,
        query: {
          ...this.$route.query,
          page,
        },
      };

      if (page === 1) {
        delete newRoute.query.page;
      }

      return newRoute;
    },
  },
};
</script>
