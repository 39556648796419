export const state = () => {
  return {
    counties: [],
    professionGroups: [],
    popularProfessions: [],
    popularProfessionTasks: [],
    phoneCountryCodes: [],
  };
};

export const getters = {
  popularProfessions: (state) => state.popularProfessions,
  popularProfessionTasks: (state) => state.popularProfessionTasks,
  counties: (state) => state.counties,
  professionGroups: (state) => state.professionGroups,
  phoneCountryCodes: (state) => state.phoneCountryCodes,
};

export const mutations = {
  setData(
    state,
    {
      counties,
      professionGroups,
      popularProfessions,
      popularProfessionTasks,
      phoneCountryCodes,
    }
  ) {
    state.counties = counties;
    state.professionGroups = professionGroups;
    state.popularProfessions = popularProfessions;
    state.popularProfessionTasks = popularProfessionTasks;
    state.phoneCountryCodes = phoneCountryCodes;
  },
};

export const actions = {
  async loadBackendData({ commit }, context) {
    try {
      const INIT_QUERY = await import("./initQ.graphql");
      const callQuery = () => {
        return context.$query(INIT_QUERY);
      };
      let resp;
      if (process.server) {
        resp = await context.$cachedData(
          "backendDataNew",
          60 * 60 * 24,
          callQuery
        );
      } else {
        resp = await callQuery();
      }
      commit("setData", resp);
    } catch (err) {
      console.error(err);
      context.$sentry.captureException(err, {
        tags: {
          graphql: "storeInit",
        },
      });
    }
  },
};
