import { isError } from "~/helpers/jsutil.js";

function getCategory(data) {
  return data.category || "logger";
}

const LOG_LEVEL = process.env.LOG_LEVEL;

const LOG_LEVEL_MAPPING = {
  fatal: 60,
  error: 50,
  warn: 40,
  info: 30,
  debug: 20,
  trace: 10,
};

function shouldLog(level) {
  return LOG_LEVEL_MAPPING[LOG_LEVEL] <= LOG_LEVEL_MAPPING[level];
}

export default function (ctx, inject) {
  /*
   * Client side logger with automatic reporting to sentry and passthrough to console functions.
   */
  inject("logger", {
    /**
     * Logs error event to console, captures message to sentry
     *
     * @param {Object | Exception} data any data to log or the exception captured
     * @param {string} message
     *
     */
    fatal(message, data = {}) {
      ctx.$sentry.captureException(data, { message, level: "fatal" });
      console.error(message, data);
    },
    /**
     * Logs error event to console, captures message to sentry
     *
     * @param {Object | Exception} data any data to log or the exception captured
     * @param {string} message
     *
     */
    error(message, data = {}) {
      if (isError(data)) {
        ctx.$sentry.captureException(data, message);
      } else {
        ctx.$sentry.addBreadcrumb({
          category: "logger",
          data,
          level: "error",
        });
        ctx.$sentry.captureMessage(message);
      }
      console.error(message, data);
    },
    /**
     * Logs warning event to console, adds message and data as breadcrumb to sentry
     *
     * @param {Object} data any data to log
     * @param {string} message
     *
     */
    warn(message, data = {}) {
      ctx.$sentry.addBreadcrumb({
        category: getCategory(data),
        message,
        data,
        level: "warn",
      });
      console.warn(message, data);
    },
    /**
     * Logs info event to console, adds message and data as breadcrumb to sentry
     *
     * @param {Object} data any data to log
     * @param {string} message
     *
     */
    info(message, data = {}) {
      ctx.$sentry.addBreadcrumb({
        category: getCategory(data),
        message,
        data,
        level: "info",
      });
      console.info(message, data);
    },
    /**
     * Logs debug event to console, adds message and data as breadcrumb to sentry
     *
     * @param {Object} data any data to log
     * @param {string} message
     *
     */
    debug(message, data = {}) {
      ctx.$sentry.addBreadcrumb({
        category: getCategory(data),
        message,
        data,
        level: "debug",
      });
      if (shouldLog("debug")) {
        console.debug(message, data);
      }
    },
    /**
     * Logs trace event to console
     *
     * @param {Object} data any data to log
     * @param {string} message
     *
     */
    trace(message, data = {}) {
      if (shouldLog("trace")) {
        console.trace(message, data);
      }
    },
  });
}
