import {
  ref,
  useRoute,
  useRouter,
} from "@nuxtjs/composition-api";
import { useDataTableFilterOperators } from "~/components/_refactored/conductor/data-table/useDataTableFilterOperators";

export function useDataTableFilter() {
  const route = useRoute();
  const router = useRouter();
  
  const query = route.value.query;

  const filterEditorModal = ref(null);
  const filterValues = ref(query?.filters ? JSON.parse(query.filters) : []);

  const OPERATORS = useDataTableFilterOperators();

  const openAddFilter = (filter) => {
    if (!filterEditorModal.value) {
      throw new Error("Filter Editor Modal not initialized.");
    }

    filterEditorModal.value.open({
      filterId: filter.id,
      operator: filter.operators?.[0] ?? OPERATORS.EQUALS,
      min: 0,
      max: 0,
      items: [],
      value: filter.defaultValue ?? "",
    });
  };

  const openEditFilter = (filterValue, index) => {
    if (!filterEditorModal.value) {
      throw new Error("Filter Editor Modal not initialized.");
    }
    filterEditorModal.value.open(filterValue, index);
  };

  const addFilter = (filterValue, index) => {
    if (index !== undefined) {
      filterValues.value.splice(index, 1, filterValue);
    } else {
      filterValues.value.push(filterValue);
    }
    router.push({
      query: {
        ...route.value.query,
        filters: JSON.stringify(filterValues.value),
      },
    });
  };

  const removeFilter = (index) => {
    filterValues.value.splice(index, 1);
    router.push({
      query: {
        ...route.value.query,
        filters: JSON.stringify(filterValues.value),
      },
    });
  }

  const removeFilters = () => {
    filterValues.value = [];
    router.push({
      query: {
        ...route.value.query,
        filters: undefined,
      },
    });
  }

  function getFilterSubmitData() {
    return filterValues.value.map(({ filterId, operator, min, max, items, value}) => {
      const filter = {
        filterId,
        operator,
      };

      switch(operator) {
        case OPERATORS.EQUALS:
        case OPERATORS.NOT_EQUALS:
        case OPERATORS.LIKE:
          filter.value = typeof value === "object" ? value.id ?? null : value;
          break;
        case OPERATORS.BETWEEN:
          filter.min = min;
          filter.max = max;
          break;
        case OPERATORS.IN:
        case OPERATORS.NOT_IN:
          filter.items = items.map(item => item?.id ?? null).filter(id => !!id);
          break;
      }

      return filter;
    });
  }

  return {
    filterEditorModal,
    filterValues,
    openAddFilter,
    openEditFilter,
    addFilter,
    removeFilter,
    removeFilters,
    getFilterSubmitData,
  }
}