<template>
  <div class="joszaki-data-table flex flex-col gap-2 md:text-sm">
    <JoszakiDataTableFilterEditorModal
      ref="filterEditorModal"
      :filter-options="filterOptions"
      @save="addFilter"
    />
    <div v-if="!!search || filterOptions" class="flex flex-row gap-2">
      <JoszakiInput
        v-model="searchString"
        icon-left="search"
        class="flex-1"
        :show-error-msg="false"
        :placeholder="search.placeholder"
        @input="onSearchInput"
      />
      <JoszakiDropdownButton
        type="info"
        icon-left="columns"
        icon-right="caret-down"
        :close-on-select="false"
        :title="$t('conductor.dataTable.columns')"
        :items="columnsForDropdown"
        @itemSelected="changeColumnVisibility"
      />
      <JoszakiDropdownButton
        v-if="filterOptions?.length"
        type="info"
        icon-left="filter"
        icon-right="caret-down"
        value-key="id"
        :items="filterOptions"
        :title="$t('conductor.dataTable.addFilter')"
        @itemSelected="openAddFilter"
      />
    </div>
    <div v-if="filterValues?.length">
      <div class="font-bold">{{ $t("conductor.dataTable.filters") }}</div>
      <div class="flex flex-row flex-wrap gap-2">
        <JoszakiDataTableFilterPill
          v-for="(fv, index) in filterValues"
          :key="index"
          :filter-value="fv"
          :filter-options="filterOptions"
          @edit="openEditFilter(fv, index)"
          @remove="removeFilter(index)"
        />
        <JoszakiButton
          size="sm"
          icon-left="trash"
          type="error"
          @click="removeFilters"
        >
          {{ $t("conductor.dataTable.removeFilters") }}
        </JoszakiButton>
      </div>
    </div>
    <div class="flex-1 overflow-scroll max-h-[77vh] relative">
      <table
        class="max-h-full p-4 border-spacing-2 w-full relative border border-gray-300"
      >
        <thead class="bg-primary sticky top-0 z-[5]">
          <tr>
            <th
              v-for="(column, index) in columns"
              v-show="displayedColumns.includes(column.name)"
              :key="index"
              class="p-2 text-white"
              :class="{ 'cursor-pointer': column.orderable }"
              :title="column.longLabel ?? column.label"
              @click="onColumnHeaderClick(column)"
            >
              {{ column.label }}
              <IconComponent
                v-if="column.orderable"
                :class="{ 'opacity-25': orderBy !== column.name }"
                :icon="order === 'asc' ? 'angle-down' : 'angle-up'"
                class="inline w-5"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="fetchState.pending">
            <tr
              v-for="i in pageSize"
              :key="i"
              class="[&:nth-child(even)]:bg-primary-lightest/30 hover:!bg-primary-lightest"
            >
              <td
                v-for="(column, index) in columns"
                v-show="displayedColumns.includes(column.name)"
                :key="index"
                class="p-4"
              >
                <content-placeholders>
                  <content-placeholders-text :lines="1" class="[&>*]:!mb-0" />
                </content-placeholders>
              </td>
            </tr>
          </template>
          <tr v-else-if="fetchState.error">
            <td :colspan="columns.length" class="p-2 text-error">
              {{ $t("error.unknown") }}
              <br />
              {{ fetchState.error?.message }}
            </td>
          </tr>
          <tr v-else-if="items.length === 0">
            <td :colspan="columns.length" class="p-2">
              {{ $t("conductor.dataTable.noData") }}
            </td>
          </tr>
          <tr
            v-for="row in items"
            :key="`row-${row.id}`"
            class="[&:nth-child(even)]:bg-primary-lightest/30 hover:!bg-primary-lightest"
          >
            <td
              v-for="(column, colIndex) in columns"
              v-show="displayedColumns.includes(column.name)"
              :key="`column-${colIndex}`"
              class="p-2 align-middle truncate max-w-[200px] min-w-[70px]"
            >
              <span
                v-if="!$scopedSlots[`column-${column.name}`]"
                :title="
                  column.formatter
                    ? column.formatter(row)
                    : get(row, column.name, '-')
                "
              >
                <template v-if="column.formatter">
                  {{ column.formatter(row) }}
                </template>
                <template
                  v-else-if="typeof get(row, column.name) === 'boolean'"
                >
                  {{ get(row, column.name) ? "✓" : "✗" }}
                </template>
                <template v-else>
                  {{ get(row, column.name, "-") }}
                </template>
              </span>
              <slot :name="`column-${column.name}`" :row="row" />
            </td>
          </tr>
        </tbody>
        <tfoot v-if="pagination" class="bg-primary sticky bottom-0 z-[5]">
          <tr>
            <td :colspan="columns.length" class="p-2 text-white">
              {{
                $t("conductor.dataTable.total", {
                  total: pagination?.total,
                  pages: pagination?.last,
                })
              }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="pt-2 flex flex-col md:flex-row justify-between">
      <JoszakiDropdown
        v-model="pageSize"
        :items="pageSizeOptions"
        @select="onPageSizeChange"
      />
      <PaginationComponent
        v-if="pagination"
        show-first-last
        :pagination-info="pagination"
        :increment="4"
      />
    </div>
  </div>
</template>

<script>
import {
  ref,
  useFetch,
  useRoute,
  useRouter,
  useContext,
  computed,
} from "@nuxtjs/composition-api";
import { watch } from "vue";
import get from "lodash/get";
import PaginationComponent from "~/components/_refactored/common/PaginationComponent.vue";
import JoszakiDataTableFilterPill from "~/components/_refactored/conductor/data-table/JoszakiDataTableFilterPill.vue";
import JoszakiDataTableFilterEditorModal from "~/components/_refactored/conductor/data-table/JoszakiDataTableFilterEditorModal.vue";
import { debounce } from "~/helpers/debounce";
import { useDataTableFilter } from "~/components/_refactored/conductor/data-table/useDataTableFilter";

export default {
  components: {
    PaginationComponent,
    JoszakiDataTableFilterPill,
    JoszakiDataTableFilterEditorModal,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    search: {
      type: Object,
      default: () => null,
    },
    filterOptions: {
      type: Array,
      default: () => [],
    },
    load: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const { i18n } = useContext();

    const pageSizeOptions = [15, 30, 50, 100, 250].map((pageSize) => ({
      value: pageSize,
      label: i18n.t("conductor.dataTable.perPage", { pageSize }),
    }));

    const query = route.value.query;
    const orderBy = ref(query?.orderBy ?? "id");
    const order = ref(query?.order ?? "asc");
    const searchString = ref(query?.search ?? "");
    const page = ref(Number(query?.page ?? 1));
    const pageSize = ref(Number(query?.pageSize ?? 15));
    const items = ref([]);
    const pagination = ref(null);
    const {
      filterValues,
      addFilter,
      filterEditorModal,
      getFilterSubmitData,
      openAddFilter,
      openEditFilter,
      removeFilter,
      removeFilters,
    } = useDataTableFilter();

    const displayedColumns = ref(
      query?.columns
        ? query.columns.split(",")
        : props.columns
            .filter((column) => !column.hidden)
            .map((column) => column.name)
    );
    const columnsForDropdown = computed(() =>
      props.columns.map((column) => {
        const isDisplayed = displayedColumns.value.includes(column.name);
        return {
          value: column.name,
          label: `${isDisplayed ? "✓" : "✗"} ${column.label}`,
        };
      })
    );

    const changeColumnVisibility = (column) => {
      const index = displayedColumns.value.indexOf(column.value);
      if (index !== -1) {
        displayedColumns.value.splice(index, 1);
      } else {
        displayedColumns.value.push(column.value);
      }

      router.push({
        query: {
          ...route.value.query,
          columns: displayedColumns.value.join(","),
        },
      });
    };

    const { fetch, fetchState } = useFetch(async () => {
      items.value = [];
      const response = await props.load({
        page: page.value,
        pageSize: pageSize.value,
        search: searchString.value,
        orderBy: orderBy.value,
        order: order.value,
        filters: getFilterSubmitData(),
      });

      pagination.value = response.pagination;
      items.value = response.items;
    });

    watch(
      () => route.value.query,

      (query) => {
        page.value = Number(query?.page ?? 1);
        fetch();
      }
    );

    const onColumnHeaderClick = (column) => {
      if (!column.orderable) {
        return;
      }

      if (orderBy.value === column.name) {
        order.value = order.value === "asc" ? "desc" : "asc";
      } else {
        orderBy.value = column.name;
        order.value = "asc";
      }

      router.push({
        query: {
          ...route.value.query,
          orderBy: orderBy.value,
          order: order.value,
          page: 1,
        },
      });
    };

    const onSearchInput = debounce(async function (search) {
      await router.push({
        query: {
          ...route.value.query,
          search,
          page: 1,
        },
      });
    }, 500);

    const onPageSizeChange = (pageSize) => {
      router.push({
        query: {
          ...route.value.query,
          pageSize: pageSize.value,
          page: 1,
        },
      });
    };

    return {
      pageSizeOptions,
      orderBy,
      order,
      searchString,
      page,
      pageSize,
      items,
      pagination,
      fetch,
      fetchState,
      onColumnHeaderClick,
      onSearchInput,
      onPageSizeChange,
      get,
      filterValues,
      columnsForDropdown,
      displayedColumns,
      changeColumnVisibility,
      openAddFilter,
      openEditFilter,
      addFilter,
      removeFilter,
      removeFilters,
      filterEditorModal,
    };
  },
  fetchOnServer: false,
};
</script>
