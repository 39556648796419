export const state = () => {
  return {
    city: undefined,
    profession: undefined,
    county: undefined,
    professionTask: undefined,
  };
};

export const getters = {
  city: (state) => state.city,
  county: (state) => state.county,
  profession: (state) => state.profession,
  professionTask: (state) => state.professionTask,
  hasPTaskContent: (state) =>
    state.professionTask?.suggestionContent?.filter((x) => x.type === "content")
      .length,
};

export const mutations = {
  setCity(state, city) {
    state.city = city;
  },
  setProfession(state, profession) {
    state.profession = profession;
  },
  setCounty(state, county) {
    state.county = county;
  },
  setProfessionTask(state, professionTask) {
    state.professionTask = professionTask;
  },
};

export const actions = {
  setCity({ commit }, city) {
    commit("setCity", city);
  },
  setProfession({ commit }, profession) {
    commit("setProfession", profession);
  },
  setCounty({ commit }, county) {
    commit("setCounty", county);
  },
  setProfessionTask({ commit }, professionTask) {
    commit("setProfessionTask", professionTask);
  },
};
