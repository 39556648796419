import { useCookieManager } from "./composables/useCookieManager";
import { useAuthStore } from "./store";

export async function loadAuth(context) {
  const { app, route, $logger } = context;

  const authStore = useAuthStore(context.$pinia);
  const cookieManager = useCookieManager(context);

  // Load tokens from cookies into store
  const token = cookieManager.getTokenCookie();
  const adminToken = cookieManager.getTokenCookie("admin");

  if (token) authStore.setToken(token);
  if (adminToken) authStore.setAdminToken(adminToken);

  if (
    !!token &&
    !route.path.startsWith(app.localePath({ name: "registrationPage" }))
  ) {
    try {
      await authStore.loginWithToken(token);
    } catch (error) {
      $logger.error(error);
    }
  }
}
