import { loadAuth } from "~/components/_refactored/auth";

/**
 * Preload store data for pinia
 */
export default async (context) => {
  if (process.server) {
    // Load data on the server side
    await Promise.all([loadAuth(context)]);
  }
  if (process.client) {
    // Load data on the client side
  }
};
