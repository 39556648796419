const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['heroku'] = require('../middleware/heroku.js')
middleware['heroku'] = middleware['heroku'].default || middleware['heroku']

middleware['router'] = require('../middleware/router.js')
middleware['router'] = middleware['router'].default || middleware['router']

export default middleware
