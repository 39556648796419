import Vue from "vue";

/**
 * This plugin facilitates automated reporting of any Google Optimize
 * experiments currently running on the page via Google Tag Manager.
 *
 * Google Optimize itself is included as a tag in Google Tag Manager.
 */
export default function ({ app }) {
  function queryOptimizeExperiment() {
    /**
     * The special `optimize.callback` event requires a custom signature,
     * specifically only working if that signature is passed in as an
     * `arguments` array. This is not explicitly documented, but is the
     * canonical implementation of the `gtag` function.
     *
     * `function gtag () { dataLayer.push(arguments) }`
     *
     * For more information, see:
     * https://support.google.com/optimize/answer/9059383.
     */
    app.$track.customEvent(arguments);
  }

  function trackOptimizeExperiment(variant, id) {
    app.$track.analyticsEvent({
      event: "Launch experiment",
      category: "Google Optimize",
      action: "Launch experiment",
      label: id,
      value: variant,
    });
  }

  if (Vue.gtm.enabled()) {
    // Special Google Optimize event to extract experiment ID and variant
    queryOptimizeExperiment("event", "optimize.callback", {
      callback: trackOptimizeExperiment,
    });
  }
}
