import { defineStore } from "pinia";
import PERSON_WITH_TOP_ANSWERS from "./queries/personWithTopAnswers.graphql";
import useRestClient from "~/helpers/restClient";
import PROFILE_BY_SEONAME from "~/graphql/profileBySeoName.graphql";

export const useProfessionalStore = defineStore("professional", {
  state: () => ({
    person: null,
    personLoading: false,
    personLoaded: false,
    city: null,
    profession: null,
    hasPicture: 1,
  }),
  getters: {
    prices: (state) => {
      return state.person?.prices || [];
    },
    rankedProfessions: (state) => {
      let professions = [...(state.person?.professions ?? [])];
      // Sort by wincount in descending order
      professions.sort((a, b) => b.winCount - a.winCount);
      if (state.profession) {
        const index = professions.findIndex(
          (x) => x.id === state.profession.id
        );
        if (index !== -1) {
          professions = professions.filter((x) => x.id !== state.profession.id);
          professions.unshift(state.profession);
        }
      }
      return professions;
    },
  },
  actions: {
    async loadPerson(seoName) {
      const { profiles, professions, cities } = useRestClient(this.$nuxt.$api);
      if (this.personLoaded && seoName === this.person?.seoName) {
        // don't reload if already loaded
        return;
      }
      try {
        this.personLoading = true;
        const promises = [profiles.bySeoName(seoName)];
        const professionSeoName = this.$nuxt.route.query.profession;
        const citySeoName = this.$nuxt.route.query.city;
        if (professionSeoName) {
          promises.push(professions.bySeoName(professionSeoName));
        } else {
          promises.push(() => Promise.resolve(null));
        }
        if (citySeoName) {
          promises.push(cities.bySeoName(citySeoName));
        } else {
          promises.push(() => Promise.resolve(null));
        }
        promises.push(
          this.$nuxt.$query(PERSON_WITH_TOP_ANSWERS, {
            seoName,
          })
        );
        const [profileRes, profession, city, personWithTopAnswers] =
          await Promise.all(promises);
        if (profileRes.status === 404) {
          return this.$nuxt.error({
            statusCode: 404,
            path: this.$nuxt.localePath({
              name: "professionalPage",
              params: { professionalSeoName: seoName },
            }),
            message: "profile",
          });
        }
        if (profileRes.status === 410) {
          return this.$nuxt.error({
            statusCode: 410,
            path: this.$nuxt.localePath({
              name: "professionalPage",
              params: { professionalSeoName: seoName },
            }),
            message: "profile",
          });
        }
        if (profileRes.status === 301) {
          return this.$nuxt.redirect(
            301,
            this.$nuxt.localePath({
              name: "professionalPage",
              params: { professionalSeoName: profileRes.data.location },
            })
          );
        }
        if (profileRes.status === 200) {
          this.person = {
            ...profileRes.data,
            ...personWithTopAnswers.personBySeoName,
          };
          this.profession = profession;
          this.city = city;
          this.personLoaded = true;
        }
      } catch (err) {
        console.error(err);
        this.$nuxt.$sentry.captureException(err, {
          tags: {
            page: "professional",
          },
        });
        return Promise.reject(err);
      } finally {
        this.personLoading = false;
      }
    },
    async loadPersonAdmin(seoName) {
      try {
        this.personLoading = true;
        const professionSeoName = this.$nuxt.route.query.profession;
        const citySeoName = this.$nuxt.route.query.city;
        const resp = await this.$nuxt.$query(PROFILE_BY_SEONAME, {
          seoName,
          citySeoName,
          professionSeoName,
        });

        this.person = resp.personBySeoName;
        this.city = resp.cityBySeoName;
        this.profession = resp.professionBySeoName;
        this.personLoaded = true;
      } catch (error) {
        console.error(error);
        this.$nuxt.$sentry.captureException(error, {
          tags: {
            page: "professional",
            action: "loadPersonAdmin",
          },
        });
        return Promise.reject(error);
      } finally {
        this.personLoading = false;
      }
    },
    async reportPerson(message) {
      try {
        const REPORT_PERSON = await import("@/graphql/reportPerson.graphql");
        return await this.$nuxt.$mutate(REPORT_PERSON, {
          message,
        });
      } catch (err) {
        console.error(err);
        this.$nuxt.$sentry.captureException(err, {
          tags: {
            professional: "report_person",
          },
        });
        return Promise.reject(err);
      }
    },
    setScoreDeleted({ id, value }) {
      const score = this.person.scores.find((x) => x.id === id);
      score.deleted = value;
    },
    async reloadPerson() {
      if (!this.person) {
        throw new Error("No person to reload");
      }
      this.personLoaded = false;
      await this.loadPersonAdmin(this.person.seoName);
    },
    pictureError() {
      this.hasPicture = 0;
    },
  },
});
