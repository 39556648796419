<template>
  <div
    class="rounded-md"
    :class="{
      '!rounded-none': styling === 'squared',
    }"
  >
    <slot name="label" :failed="failed">
      <p
        v-if="label"
        class="text-base font-bold"
        :class="{
          'text-error': failed,
        }"
      >
        {{ label }}
      </p>
    </slot>
    <slot name="helperText" :failed="failed">
      <p
        v-if="helperText"
        class="text-base text-gray-500 mb-2"
        :class="{
          'text-error': failed,
        }"
      >
        {{ helperText }}
      </p>
    </slot>

    <textarea
      ref="description"
      v-model="valueModel"
      :placeholder="placeholder"
      class="w-full min-h-[2.5rem] border border-solid p-3 hover:border-gray-400 focus-visible:border-primary focus-visible:outline-none focus-visible:shadow-md placeholder:text-gray-400 rounded-md"
      :class="{
        'border-gray-300': !failed,
        'border-error': failed,
        'resize-none': !resizeAble,
        '!rounded-none': styling === 'squared',
        'h-48': !autoSize,
      }"
      :disabled="_disabled"
      v-bind="$attrs"
      @click="$emit('click')"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
    <p
      v-if="showErrorMsg"
      class="text-sm text-error"
      :class="{
        invisible: !failed,
      }"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { inject } from "@nuxtjs/composition-api";

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    helperText: {
      type: String,
      default: null,
    },
    resizeAble: {
      type: Boolean,
      default: false,
    },
    iconLeft: {
      type: String,
      default: "",
    },
    iconPackLeft: {
      type: String,
      default: "fas",
    },
    iconRight: {
      type: String,
      default: "",
    },
    iconPackRight: {
      type: String,
      default: "fas",
    },
    placeholder: {
      type: String,
      default: "",
    },
    validationState: {
      type: Object,
      required: false,
      default: () => {},
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showErrorMsg: {
      type: Boolean,
      default: true,
    },
    reserveErrorSpace: {
      type: Boolean,
      default: true,
    },
    styling: {
      type: String,
      default: "rounded",
      validator: (val) => ["rounded", "squared"].includes(val),
    },
    autoSize: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { disabled: formDisabled } = inject("form", { disabled: false });
    return {
      formDisabled,
    };
  },
  computed: {
    _disabled() {
      return this.disabled || this.formDisabled;
    },
    valueModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    hasIconRight() {
      return this.failed || !!this.iconRight;
    },
    errorMessage() {
      return (
        this.validationState?.$errors?.[0]?.$message ??
        (this.reserveErrorSpace
          ? "placeholder so height is correctly computed"
          : "")
      );
    },
    failed() {
      return this.validationState?.$error;
    },
  },
  watch: {
    value() {
      if (!this.autoSize) {
        return;
      }

      this.$refs.description.style.height = "auto";
      this.$nextTick(() => {
        this.$refs.description.style.height = `${
          this.$refs.description.scrollHeight + 10
        }px`;
      });
    },
  },
  mounted() {
    if (this.autofocus) {
      this.focus();
    }
    if (this.autoSize) {
      this.$refs.description.style.height = "auto";
      this.$refs.description.style.height = `${
        this.$refs.description.scrollHeight + 10
      }px`;
    }
  },
  methods: {
    focus() {
      this.$refs.description.focus();
    },
  },
};
</script>
