<template>
  <JoszakiPill>
    <span class="truncate max-w-3xl">
    {{
      $t(`conductor.dataTable.operators.${filterValue.operator}`, {
        field: filterOption?.label,
        min: filterValue.min,
        max: filterValue.max,
        value,
        items,
      })
    }}
    </span>
    <IconComponent icon="pen" class="ml-2 w-5 cursor-pointer" @click.native="edit" />
    <IconComponent
      icon="times-circle"
      class="ml-2 w-5 cursor-pointer"
      @click.native="remove"
    />
  </JoszakiPill>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    filterOptions: {
      type: Array,
      required: true,
    },
    filterValue: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const filterOption = computed(() => {
      if (!props.filterValue) {
        return null;
      }

      return props.filterOptions.find(
        (option) => option.id === props.filterValue.filterId
      );
    });

    const value = computed(() => {
      if (typeof props.filterValue.value === "object") {
        return props.filterValue.value.name;
      }
      if (typeof props.filterValue.value === "boolean") {
        return props.filterValue.value ? "✓" : "✗";
      }
      return props.filterValue.value;
    });

    const items = computed(() => {
      return (props.filterValue.items ?? [])
          .map((item) => item?.name ?? item)
          .join(", ");
    });

    const edit = () => {
      emit("edit", props.filterValue);
    };

    const remove = () => {
      emit("remove", props.filterValue);
    };

    return {
      edit,
      remove,
      filterOption,
      value,
      items,
    };
  },
};
</script>
