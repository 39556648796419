<template>
  <h1
    v-if="level === 1"
    class="JoszakiHeading text-2xl lg:text-4xl pt-2 pb-1 font-bold text-primary"
  >
    <slot />
  </h1>
  <h2
    v-else-if="level === 2"
    class="JoszakiHeading text-xl lg:text-3xl pt-2 pb-1 font-bold text-primary"
  >
    <slot />
  </h2>
  <h3
    v-else-if="level === 3"
    class="JoszakiHeading text-lg lg:text-2xl pt-2 pb-1 font-bold text-primary"
  >
    <slot />
  </h3>
  <h4
    v-else
    class="JoszakiHeading text-md lg:text-xl pt-2 pb-1 font-bold text-primary"
  >
    <slot />
  </h4>
</template>

<script>
export default {
  name: "JoszakiHeading",
  props: {
    level: {
      type: Number,
      default: 1,
    },
  },
};
</script>
