<template>
  <div>
    <div v-for="option in options" :key="option.value">
      <input
        :id="`radio-${option.value}`"
        v-model="radioValue"
        type="radio"
        :value="option.value"
        name="default-radio"
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        :class="{
          '!text-error !bg-error !border-error': failed,
        }"
      />
      <label
        :for="`radio-${option.value}`"
        class="ml-2 text-sm text-gray-900 dark:text-gray-300 font-semibold"
        :class="{
          '!text-error': failed,
        }"
        >{{ option.label }}</label
      >
    </div>
    <div
      class="text-error font-bold mt-2"
      :class="{
        invisible: !failed,
      }"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Boolean],
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    validationState: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    radioValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    errorMessage() {
      return (
        this.validationState?.$errors?.[0]?.$message ||
        "placeholder so height is correctly computed"
      );
    },
    failed() {
      return this.validationState?.$error;
    },
  },
  //
};
</script>
