<template>
  <JoszakiCard hoverable>
    <div
      class="flex items-center flex-row cursor-pointer"
      :class="{
        'pb-4': isOpen,
      }"
      @click="onHeaderClick"
    >
      <IconComponent
        v-if="icon"
        :icon="icon"
        :pack="iconPack"
        class="w-8"
        :class="iconClass"
      />
      <span class="font-bold text-xl flex-1">
        {{ title }}
      </span>
      <IconComponent v-if="isOpen" icon="angle-down" />
      <IconComponent v-else icon="angle-right" />
    </div>
    <div v-show="isOpen" :class="innerClass">
      <slot />
    </div>
  </JoszakiCard>
</template>

<script>
export default {
  name: "JoszakiCollapsibleCard",
  props: {
    innerClass: {
      type: [String, Object],
      default: "",
    },
    icon: {
      type: String,
      default: null,
    },
    iconPack: {
      type: String,
      default: "fas",
    },
    iconClass: {
      type: String,
      default: "text-primary",
    },
    title: {
      type: String,
      required: true,
    },
    isOpenByDefault: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.isOpenByDefault,
    };
  },
  methods: {
    onHeaderClick() {
      if (this.$slots.default) {
        this.isOpen = !this.isOpen;
      }

      this.$emit("toggle");
      if (this.isOpen) {
        this.$emit("open");
      } else {
        this.$emit("close");
      }
    },
  },
};
</script>
