import { defineStore } from "pinia";
import { useUserStore } from "~/stores/user";

const CLOSED_QUESTIONS = "joszaki-closed-questions";

// don't use yet, need to port other stores first
export const useFeedStore = defineStore("feedStore", {
  state: () => ({
    marketFeed: null,
    marketFeedLoading: false,
    marketFeedLoaded: false,
    openPersonTenderFeed: null,
    openPersonTenderFeed2: null,
    openPersonTenderFeedLoading: false,
    openPersonTenderFeedLoaded: false,
    questionFeed: null,
    questionFeedLoading: false,
    questionFeedLoaded: false,
    skippedQuestionProfessions: [],
    skippedQuestions: localStorage.getItem(CLOSED_QUESTIONS)
      ? JSON.parse(localStorage.getItem(CLOSED_QUESTIONS))
      : [],
  }),
  getters: {
    questions() {
      return this.questionFeed?.questions.filter(
        (x) =>
          !this.skippedQuestions.includes(x.id) &&
          !this.skippedQuestionProfessions.includes(x.profession.id)
      );
    },
  },
  actions: {
    reset() {
      this.$reset();
    },
    updateOpenTender(personTender) {
      if (this.openPersonTenderFeed?.personTenders) {
        for (const tender of this.openPersonTenderFeed.personTenders) {
          if (tender.id === personTender.id) {
            Object.assign(tender, personTender);
            break;
          }
        }
      }
    },
    async loadOpenPersonTenderFeed(variables = { cursor: null }) {
      if (this.openPersonTenderFeedLoaded && !variables.cursor) {
        return;
      }
      const OPEN_PERSON_TENDER_QUERY = await import(
        "@/graphql/openPersonTenderFeed.graphql"
      );
      try {
        this.openPersonTenderFeedLoading = true;
        const result = await this.$nuxt.$query(
          OPEN_PERSON_TENDER_QUERY,
          variables
        );
        this.setOpenPersonTenderFeed(result.me.openPersonTenderFeed);
      } catch (e) {
        console.error(e);
        return Promise.reject(e);
      } finally {
        this.openPersonTenderFeedLoading = false;
      }
    },
    setOpenPersonTenderFeed(openPersonTenderFeed) {
      if (!this.openPersonTenderFeed) {
        this.openPersonTenderFeed = openPersonTenderFeed;
        this.openPersonTenderFeedLoaded = true;
      } else {
        this.openPersonTenderFeed.cursor = openPersonTenderFeed.cursor;
        for (const tender of openPersonTenderFeed.personTenders) {
          const matching = this.openPersonTenderFeed.personTenders.find(
            (x) => x.id === tender.id
          );
          if (matching) {
            continue;
          }
          this.openPersonTenderFeed.personTenders.push(tender);
        }
      }
    },
    async loadMarketFeed(variables = { cursor: null }) {
      try {
        const MARKET_QUERY = await import("@/graphql/marketQuery.graphql");
        this.marketFeedLoading = true;
        const result = await this.$nuxt.$query(MARKET_QUERY, variables);
        this.setMarketFeed(result.marketFeed);
        if (!this.marketFeed.cursor) {
          this.marketFeedLoaded = true;
        }

        return Promise.resolve(result.marketFeed);
      } catch (error) {
        console.error("loadMarketFeed", error);
        return Promise.reject(error);
      } finally {
        this.marketFeedLoading = false;
      }
    },
    async hideTender(value) {
      const tenderId = parseInt(value);
      try {
        const HIDE_TENDER = await import("./hideTender.graphql");
        const result = await this.$nuxt.$mutate(HIDE_TENDER, { tenderId });
        if (result?.ok) {
          this.marketFeed.tenders = this.marketFeed.tenders.filter(
            (x) => x.id !== value
          );
          const userStore = useUserStore();
          userStore.refetch();
        }
      } catch (err) {
        return Promise.reject(err);
      }
    },
    setMarketFeed(marketFeed) {
      if (!this.marketFeed) {
        this.marketFeed = marketFeed;
      } else {
        this.marketFeed.cursor = marketFeed.cursor;
        for (const tender of marketFeed.tenders) {
          const matching = this.marketFeed.tenders.find(
            (x) => x.id === tender.id
          );
          if (matching) {
            continue;
          }
          this.marketFeed.tenders.push(tender);
        }
      }
    },
    async buyMarketTender(value) {
      const tenderId = parseInt(value);
      try {
        const BUY_MARKET_TENDER_MUTATION = await import(
          "./buyMarketTender.graphql"
        );
        const result = await this.$nuxt.$mutate(BUY_MARKET_TENDER_MUTATION, {
          tenderId,
        });
        if (result.personTender?.id) {
          this.marketFeed.tenders = this.marketFeed.tenders.filter(
            (x) => x.id !== value
          );
          const userStore = useUserStore();
          userStore.refetch();
          this.openPersonTenderFeed = null;
          this.openPersonTenderFeedLoaded = false;
          await this.loadOpenPersonTenderFeed();
          return Promise.resolve(result);
        }
      } catch (err) {
        console.error(err);
        return Promise.reject(err);
      }
    },
    async resetAndReload() {
      this.$reset();

      await Promise.all([
        this.loadMarketFeed(),
        this.loadOpenPersonTenderFeed(),
      ]);
    },
    setQuestionFeed(questionFeed) {
      if (!this.questionFeed) {
        this.questionFeed = questionFeed;
      } else {
        this.questionFeed.hasMore = questionFeed.hasMore;
        for (const question of questionFeed.questions) {
          const matching = this.questionFeed.questions.find(
            (x) => x.id === question.id
          );
          if (matching) {
            continue;
          }
          this.questionFeed.questions.push(question);
        }
      }
    },
    async loadQuestionFeed() {
      try {
        const QUESTION_FEED_QUERY = await import("./questionFeed.graphql");
        this.questionFeedLoading = true;
        const result = await this.$nuxt.$query(QUESTION_FEED_QUERY, {
          skippedIds: this.skippedQuestions,
        });
        this.setQuestionFeed(result.questionFeed);
        if (!this.questionFeed.hasMore) {
          this.questionFeedLoaded = true;
        }

        return Promise.resolve(result.questionFeed);
      } catch (error) {
        console.error("loadQuestionFeed", error);
        return Promise.reject(error);
      } finally {
        this.questionFeedLoading = false;
      }
    },
    skipQuestion(questionId) {
      this.skippedQuestions.push(questionId);
      localStorage.setItem(
        CLOSED_QUESTIONS,
        JSON.stringify(this.skippedQuestions)
      );
    },
    skipQuestionProfession(professionId) {
      this.skippedQuestionProfessions.push(professionId);
    },
  },
});
