export function validatePassword(password, options = {}) {
  const defaultOptions = {
    minLength: 8,
    uppercase: true,
    lowercase: true,
    number: true,
    specialChar: false,
  };

  const settings = { ...defaultOptions, ...options };

  const rules = [
    {
      enabled: true,
      regex: new RegExp(`.{${settings.minLength},}`),
      errorName: "passwordNotLongEnough",
      params: { minLength: settings.minLength },
    },
    {
      enabled: settings.uppercase,
      regex: /[A-Z]/,
      errorName: "passwordMustContainUppercase",
    },
    {
      enabled: settings.lowercase,
      regex: /[a-z]/,
      errorName: "passwordMustContainLowercase",
    },
    {
      enabled: settings.number,
      regex: /\d/,
      errorName: "passwordMustContainNumber",
    },
    {
      enabled: settings.specialChar,
      regex: /[@$!%*?&.:,]/,
      errorName: "passwordMustContainSpecialChar",
    },
  ];

  for (const rule of rules) {
    if (rule.enabled && !rule.regex.test(password)) {
      return { valid: false, errorName: rule.errorName, params: rule.params };
    }
  }

  return { valid: true };
}
