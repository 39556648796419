var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.options),function(option){return _c('div',{key:option.value},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.radioValue),expression:"radioValue"}],staticClass:"w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600",class:{
        '!text-error !bg-error !border-error': _vm.failed,
      },attrs:{"id":`radio-${option.value}`,"type":"radio","name":"default-radio"},domProps:{"value":option.value,"checked":_vm._q(_vm.radioValue,option.value)},on:{"change":function($event){_vm.radioValue=option.value}}}),_c('label',{staticClass:"ml-2 text-sm text-gray-900 dark:text-gray-300 font-semibold",class:{
        '!text-error': _vm.failed,
      },attrs:{"for":`radio-${option.value}`}},[_vm._v(_vm._s(option.label))])])}),_c('div',{staticClass:"text-error font-bold mt-2",class:{
      invisible: !_vm.failed,
    }},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }