<template>
  <div class="joszaki-table max-h-full flex flex-col">
    <div
      class="flex-initial bg-primary text-white text-xl text-left font-bold p-2"
    >
      <slot name="header" />
    </div>
    <div
      class="flex-1 overflow-auto relative"
      :class="{
        'border-solid border-l border-info': borders,
      }"
    >
      <table
        class="max-h-full p-4 border-spacing-2 balance-table w-full relative"
        :aria-label="caption"
        cellspacing="0"
      >
        <thead class="bg-primary sticky top-0">
          <slot name="thead" />
        </thead>
        <tbody
          :class="{
            '[&>*:nth-child(even)]:bg-primary-lightest/30': !borders,
            '[&_td]:border-solid [&_td]:border [&_td]:border-info': borders,
          }"
        >
          <slot name="tbody" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    caption: {
      type: String,
      required: true,
    },
    borders: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="postcss">
.joszaki-table th {
  @apply py-4 !text-white;
}

.joszaki-table tr > th:first-child {
  @apply !pl-2;
}

.joszaki-table tr > td {
  @apply align-middle py-1;
}

.joszaki-table thead > tr {
  @apply py-2;
}
</style>
