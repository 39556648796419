<template>
  <JoszakiAutocomplete
    ref="professionAutocomplete"
    v-model="professionFilter"
    icon="briefcase"
    :items="filteredProfessions"
    v-bind="$attrs"
    :group-field="professionFilter ? null : 'group'"
    :formatter="formatter"
    :label="showLabel ? label ?? $t('common.profession') : null"
    :keep-first="false"
    :placeholder="value?.name ?? placeholder ?? ''"
    :loading="professionsLoading"
    @select="select"
    @focus="onFocus"
    @blur="onBlur"
    @remove="onRemove"
  >
    <template #empty>
      <span>
        {{ $t("professionAutocomplete.empty") }}
      </span>
    </template>
  </JoszakiAutocomplete>
</template>

<script>
import { mapState } from "pinia";
import { debounce } from "~/helpers/debounce";
import { useLazyStore } from "~/stores/lazy";

const KEYWORD = "keyword";

export default {
  model: {
    prop: "value",
    event: "select",
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    keywordSearch: {
      type: Boolean,
      default: false,
    },
    typingTrackerEvent: {
      type: String,
      default: null,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    onlyWithProfessionTasks: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      lazyStore: useLazyStore(),
    };
  },
  data() {
    return {
      professionFilter: "",
      professionSearch: "",
      focused: false,
    };
  },
  computed: {
    ...mapState(useLazyStore, {
      professionsFromStore: "professions",
      professionsLoading: "professionsLoading",
    }),
    professions() {
      return this.professionsFromStore.map((p) => ({
        ...p,
        group: p.professionGroup?.name ?? "",
      }));
    },
    normalizedProfessionFilter() {
      return this.$helpers.normalizeInput(this.professionFilter);
    },
    filteredProfessions() {
      let professions = this.professions;

      if (this.onlyWithProfessionTasks) {
        professions = professions.filter((p) => p.professionTaskCount > 0);
      }

      if (!this.professionFilter) {
        return professions;
      }

      const rankedList = [];
      for (const p of professions) {
        const index = this.$helpers
          .normalizeInput(p.name)
          .indexOf(this.normalizedProfessionFilter);
        if (index > -1) {
          rankedList.push({
            index,
            profession: p,
          });
        }
      }
      rankedList.sort((a, b) => {
        if (a.index === b.index) {
          return a.profession.seoName > b.profession.seoName ? 1 : -1;
        }
        return a.index > b.index ? 1 : -1;
      });
      const result = rankedList.map((x) => x.profession);
      if (this.keywordSearch && this.professionFilter) {
        // Hacky ez workaround
        result.push({
          id: KEYWORD,
          group: this.$t("peopleHeader.profession.keyword"),
          name: this.professionFilter,
        });
      }
      return result;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          this.professionFilter = "";
          return;
        }
        if (newValue.name !== this.cityFilter) {
          this.professionFilter = newValue.name;
        }
      },
    },
    professionFilter(...args) {
      if (this.typingTrackerEvent) {
        this.debouncedTrackTyping(...args);
      }
    },
  },
  mounted() {
    this.setSelected(this.value);

    if (this.typingTrackerEvent) {
      this.debouncedTrackTyping = debounce((newValue, _oldValue) => {
        this.$trackers.trackEvent({
          title: this.typingTrackerEvent,
          data: { value: newValue },
        });
      }, 500);
    }
  },
  methods: {
    focusInput() {
      try {
        this.$refs.professionAutocomplete.focus();
      } catch (error) {
        console.info("Cant auto focus professionAutocomplete input");
      }
    },
    formatter(value) {
      if (!value) {
        return "NULL";
      }
      return this.$helpers.capitalize(value.name);
    },
    setSelected(profession) {
      if (!profession) {
        return;
      }
      this.professionFilter = profession.name;
    },
    onFocus() {
      this.focused = true;
      this.lazyStore.loadProfessions();
      this.professionFilter = this.professionSearch;
      this.$emit("focus");
    },
    onBlur() {
      this.focused = false;
      this.professionSearch = this.professionFilter;
      this.professionFilter = this.value?.name ?? "";
      this.$emit("blur");
    },
    select(profession) {
      let toEmit = profession;
      if (profession && profession.id === KEYWORD) {
        // Emit keyword instead of hacky object
        toEmit = profession.name;
      }
      this.professionSearch = "";

      this.$emit("select", toEmit);
    },
    onRemove(profession) {
      this.professionSearch = "";
      if (profession) {
        this.$emit("remove", profession);
      } else {
        this.onBlur();
      }
    },
    blur() {
      this.$refs.professionAutocomplete.isActive = false;
    },
    focus() {
      this.$refs.professionAutocomplete.focusInput();
    },
  },
};
</script>
