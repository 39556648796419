export const state = () => {
  return {
    city: { name: "Ország szerte", seoName: "" },
    searchQuery: "",
    loading: false,
    loaded: false,
    success: false,
  };
};

export const getters = {
  city: (state) => state.city,
  loading: (state) => state.loading,
  loaded: (state) => state.loaded,
  searchQuery: (state) => state.searchQuery,
};

export const mutations = {
  setCity(state, city) {
    state.city = city;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setLoaded(state, payload) {
    state.loaded = payload;
  },
  setSuccess(state, payload) {
    state.success = payload;
  },
  setSearchQuery(state, payload) {
    state.searchQuery = payload;
  },
};

export const actions = {
  setSearchQuery(context, payload) {
    context.commit("setSearchQuery", payload);
  },
  setCity(context, payload) {
    context.commit("setCity", payload);
  },
  setLoading(context, payload) {
    context.commit("setLoading", payload);
  },
  setLoaded(context, payload) {
    context.commit("setLoaded", payload);
  },
  setSuccess(context, payload) {
    context.commit("setSuccess", payload);
  },
};
