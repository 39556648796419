/**
 * prefer this way to instance of because that one has limitations
 */
export function isError(e) {
  return (
    e &&
    e.stack &&
    e.message &&
    typeof e.stack === "string" &&
    typeof e.message === "string"
  );
}

/**
 * Use only on server side
 * @param {*} name
 * @returns
 */
export function ensureEnv(name) {
  if (process.client) {
    throw new Error("ensureEnv is only available on server side");
  }
  const value = process.env[name];
  if (!value) {
    console.error(`Missing env variable ${name}`);
    throw new Error(`Missing env variable ${name}`);
  }
  return value;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
