<template>
  <div
    class="relative flex justify-start items-center group text-md"
    @click="onClick"
  >
    <label>{{ label }}</label>
    <input
      type="checkbox"
      class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md cursor-pointer"
      :value="value"
    />
    <span
      class="w-8 h-5 flex items-center flex-shrink-0 ml-4 p-1 bg-gray-300 rounded-full duration-300 ease-in-out after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300"
      :class="{
        'after:translate-x-2 bg-green-400': value,
      }"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      this.$emit("input", !this.value);
    },
  },
};
</script>
