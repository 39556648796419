import { useContext, computed } from "@nuxtjs/composition-api";
import { defineStore, storeToRefs } from "pinia";
import { useAuthStore } from "../auth";
import { useFeedStore } from "~/stores/user/feed";
import { useUserStore } from "~/stores/user";

export const useAdminUserSwitcher = defineStore("useAdminUserSwitcher", () => {
  const { $mutate } = useContext();

  const authStore = useAuthStore();
  const { userToken, adminToken } = storeToRefs(authStore);
  const userStore = useUserStore();

  const switchedToMate = computed(() => {
    if (userToken.value && adminToken.value) {
      return userToken.value !== adminToken.value;
    }
    return false;
  });

  function resetStores() {
    // TODO feedStore is not fit for server side rendering bcs it's using browser only API's so this statement is put in the body of the function
    const feedStore = useFeedStore();
    userStore.$reset();
    feedStore.$reset();
  }

  async function loginAsUser(personId) {
    if (userStore.me?.id === personId) {
      return;
    }
    const LOGIN_AS_USER = await import("./loginAsUser.graphql");

    const response = await $mutate(LOGIN_AS_USER, {
      id: personId,
    });

    resetStores();
    await authStore.loginWithToken(response.token);
  }

  async function switchBackToAdmin() {
    resetStores();
    await authStore.loginWithToken(authStore.adminToken);
  }

  return {
    loginAsUser,
    switchBackToAdmin,
    switchedToMate,
  };
});
