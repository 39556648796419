<template>
  <JoszakiPage>
    <ErrorProfile v-if="error.message === 'profile'" />
    <div v-else class="flex flex-col items-center gap-4">
      <div class="text-3xl">
        {{ error.statusCode }}
      </div>
      <div class="text-5xl font-bold">
        {{ $t("error404.title") }}
      </div>
      <div
        :style="{ backgroundImage: `url(${backgroundUrl})` }"
        class="w-full max-w-xs lg:max-w-[400px] aspect-square bg-cover"
      />
      <Error404 v-if="error.statusCode === 404" />
      <Error500 v-else-if="error.statusCode === 500" />
    </div>
  </JoszakiPage>
</template>

<script>
import {
  useContext,
  useRoute,
  useRouter,
  watch,
  defineComponent,
} from "@nuxtjs/composition-api";
import Error404 from "~/components/_refactored/error/Error404.vue";
import Error500 from "~/components/_refactored/error/Error500.vue";
import ErrorProfile from "~/components/_refactored/profile/ErrorProfile.vue";
import { useUserStore } from "~/stores/user";
import { useProfessionalStore } from "~/stores/professional";

export default defineComponent({
  name: "ErrorLayout",
  components: { Error404, ErrorProfile, Error500 },
  layout: "DefaultLayout",
  props: {
    error: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup() {
    const userStore = useUserStore();
    const professionalStore = useProfessionalStore();
    const route = useRoute();
    const router = useRouter();
    const context = useContext();

    watch(
      () => userStore.me,
      async () => {
        if (userStore.isAdmin && route?.value?.params?.professionalSeoName) {
          await professionalStore.loadPersonAdmin(
            route.value.params.professionalSeoName
          );
          if (professionalStore.person) {
            // TODO temp workaround with random query param so we can navigate to the same page
            router.push(
              context.localePath({
                name: "professionalPage",
                params: {
                  professionalSeoName: professionalStore.person.seoName,
                },
                query: { ...route.value.query, random: Math.random() },
              })
            );
          }
        }
      },
      {
        immediate: true,
      }
    );
  },
  data() {
    return {
      backgroundUrl: require("@/assets/joszaki-error.png"),
    };
  },
});
</script>
