<template>
  <b-field
    :label="label"
    :type="failed ? 'is-danger' : ''"
    :message="errorMessage"
  >
    <b-datepicker
      v-model="datePickerValue"
      :first-day-of-week="1"
      :locale="$i18n.locale"
      :placeholder="placeholder"
      :min-date="minDate"
      :max-date="maxDate"
      :mobile-native="false"
      :editable="false"
    />
  </b-field>
</template>

<script>
export default {
  props: {
    value: {
      type: Date,
      default: null,
    },
    minDate: {
      type: Date,
      default: null,
    },
    maxDate: {
      type: Date,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    validationState: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    datePickerValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    errorMessage() {
      return this.validationState?.$errors?.[0]?.$message; // || 'placeholder so height is correctly computed'
    },
    failed() {
      return this.validationState?.$error;
    },
  },
};
</script>
