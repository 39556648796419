<template>
  <div class="flex items-stretch">
    <slot name="addons-left" />
    <input
      ref="input"
      class="h-10 flex-1 min-w-0 border-solid border border-black/20 px-2"
      :value="value"
      v-bind="$attrs"
      @keydown="$emit('keydown', $event)"
      @input="onInput"
    />
    <slot name="addons-right" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Boolean, Number],
      required: false,
      default: null,
    },
    type: {
      type: String,
      default: "text",
      // TODO add more as needed
      validator: (val) => ["text", "number"].includes(val),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onInput(event) {
      const value = event.target.value;
      this.$emit("input", value);
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
