<template>
  <button
    class="bg-white text-primary rounded-full w-10 h-10 shadow-lg flex items-center justify-center"
    @click="$emit('click')"
  >
    <IconComponent v-if="icon" :icon="icon" />
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>
