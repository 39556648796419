import { EVENT_PAGE_NAMES } from "~/data/pageNames";

const ROUTE_NAME_TO_PAGE_NAME_MAP = {
  requestForTender: EVENT_PAGE_NAMES.REQUEST_TENDER_PAGE,
  requestForTenderTab: EVENT_PAGE_NAMES.REQUEST_TENDER_PAGE,
  prices: EVENT_PAGE_NAMES.PRICES_PAGE,
  priceCalculatorPage: EVENT_PAGE_NAMES.PRICE_CALCULATOR_PAGE,
  professionals: EVENT_PAGE_NAMES.PROFESSION_CITY_PAGE,
  professionPage: EVENT_PAGE_NAMES.PROFESSIONS_PAGE,
  professionsPage: EVENT_PAGE_NAMES.PROFESSION_GROUP_PAGE,
  professionalPage: EVENT_PAGE_NAMES.PROFESSIONAL_PAGE,
  county: EVENT_PAGE_NAMES.COUNTY_PAGE,
  countyCity: EVENT_PAGE_NAMES.CITY_PAGE,
  questions: EVENT_PAGE_NAMES.QUESTION_ANSWER_PAGE,
  question: EVENT_PAGE_NAMES.QUESTIONS_LANDING_PAGE,
  inspirationLandingPage: EVENT_PAGE_NAMES.INSPIRATION_LANDING_PAGE,
  inspirationProfessionPage: EVENT_PAGE_NAMES.INSPIRATION_PROFESSION_PAGE,
  inspirationProfessionTaskPage:
    EVENT_PAGE_NAMES.INSPIRATION_PROFESSION_TASK_PAGE,
  inspirationPage: EVENT_PAGE_NAMES.INSPIRATION_PAGE,
  inspirationPicturePage: EVENT_PAGE_NAMES.INSPIRATION_PICTURE_PAGE,
  salariesPage: EVENT_PAGE_NAMES.SALARIES_PAGE,
  references: EVENT_PAGE_NAMES.REFERENCES_PAGE,
  myTenders: EVENT_PAGE_NAMES.MY_TENDERS_PAGE,
};

export default function (ctx, inject) {
  const posthog = ctx.$posthog;
  const route = ctx.route;

  // Vue.prototype.$trackers = trackers
  inject("trackers", {
    trackEvent: trackEvent.bind({ posthog }),
    trackClick: trackClick.bind({ route, posthog }),
    trackRequestTender: trackRequestTender.bind({ route, posthog }),
    setUserProperties: setUserProperties.bind({ posthog }),
    identifyUser: identifyUser.bind({ posthog }),
    posthogLoadToolbar: posthogLoadToolbar.bind({ posthog }),
    pageNames: EVENT_PAGE_NAMES,
    pageview: pageview.bind({ posthog }),
    pageleave: pageleave.bind({ posthog }),
  });
}

export function resolvePageName(routeName) {
  const nonLocalizedRouteName = routeName.includes("___")
    ? routeName.split("___")[0]
    : routeName;
  return ROUTE_NAME_TO_PAGE_NAME_MAP?.[nonLocalizedRouteName];
}

/**
 * @this {ctx} object with key of posthog
 * @param {object} props
 */
function pageview(props) {
  if (process.server) {
    console.error("attempting to track pageview on server side", props);
    return;
  }
  if (process.env.DEPLOY_STAGE !== "production") {
    console.info("TRACKER - trackEvent", "pageview", props);
  }
  if (this.posthog) {
    this.posthog.capture("$pageview", props);
  } else {
    console.error("Could not send posthog event");
  }
}

/**
 * @this {ctx} object with key of posthog
 * @param {object} props
 */
function pageleave(props) {
  if (process.server) {
    console.error("attempting to track pageleave on server side", props);
    return;
  }
  if (process.env.DEPLOY_STAGE !== "production") {
    console.info("TRACKER - trackEvent", "pageleave", props);
  }

  if (process.env.POSTHOG_TRACK_PAGELEAVE !== "true") {
    if (process.env.DEPLOY_STAGE !== "production") {
      console.info(
        "TRACKER - pageleave not tracked, POSTHOG_TRACK_PAGELEAVE is not true"
      );
    }
    return;
  }

  if (this.posthog) {
    this.posthog.capture("$pageleave", props);
  } else {
    console.error("Could not send posthog event");
  }
}

/**
 *
 * @param elementId The element the user clicked on
 * @param data Other supplementary data to include in event
 * @param target For overriding target of trackEvent
 */
function trackClick(elementId, data, target) {
  if (process.env.POSTHOG_SKIP_TRACK_CLICKS === "true") {
    return;
  }
  const route = Object.assign(this.route, target?.route);

  trackEvent.call(this, {
    title: "Click",
    data: {
      pagename: resolvePageName(route?.name),
      elementId,
      // don't track admin_token
      ...Object.fromEntries(
        Object.entries(route?.query).filter(([key]) => key !== "admin_token")
      ),
      ...route?.params,
      ...route?.meta,
      ...data,
    },
    target,
  });
}

/**
 *
 * @param data Data to include in event
 * @param target For overriding target of trackEvent
 */
function trackRequestTender(data, target) {
  const route = Object.assign(this.route, target?.route);

  trackEvent.call(this, {
    title: "Request Tender",
    data: {
      pagename: resolvePageName(route?.name),
      // don't track admin_token
      ...Object.fromEntries(
        Object.entries(route?.query).filter(([key]) => key !== "admin_token")
      ),
      ...route?.params,
      ...route?.meta,
      ...data,
    },
    target,
  });
}

/**
 * @this {ctx} object with key of posthog
 * @param {*} arg event descriptor containing title data and target
 */
function trackEvent({ title, data, target }) {
  if (process.server) {
    console.error("attempting to track event on server side", title);
    return;
  }
  target = Object.assign(
    {
      posthog: true,
    },
    target
  );

  if (process.env.DEPLOY_STAGE !== "production") {
    console.info("TRACKER - trackEvent", title, data, target);
  }

  // Posthog
  if (target.posthog) {
    if (this.posthog) {
      this.posthog.capture(title, data);
    } else {
      console.error("Could not send posthog event");
    }
  }
}

/**
 * @this {ctx} object with key of posthog
 * @param {*} arg
 */
function setUserProperties({ id, data, target }) {
  if (process.server) {
    console.error("attempting to setUserProperties on server side");
    return;
  }
  if (process.env.DEPLOY_STAGE !== "production") {
    console.info("TRACKER - setUserProperties", id, data, target);
  }
  target = Object.assign(
    {
      posthog: true,
    },
    target
  );

  // Posthog
  if (target.posthog) {
    if (this.posthog) {
      this.posthog.identify(id, data);
    } else {
      console.error("Could not set posthog user properties");
    }
  }
}

/**
 * @this {ctx} object with key of posthog
 * @param {*} arg
 */
function identifyUser({ id, target }) {
  if (process.server) {
    console.error("attempting to identifyUser on server side");
    return;
  }
  if (process.env.DEPLOY_STAGE !== "production") {
    console.info("TRACKER - identifyUser", id, target);
  }
  target = Object.assign(
    {
      posthog: true,
    },
    target
  );

  // Posthog
  if (target.posthog) {
    if (this.posthog && process.browser) {
      this.posthog.identify(id);
    } else {
      console.error("Could not send posthog identify");
    }
  }
}

/**
 * @this {ctx} object with key posthog
 * @param {*} arg
 */
function posthogLoadToolbar() {
  if (process.server) {
    console.error("attempting to load posthog toolbar on server side");
    return;
  }
  if (!this.posthog) {
    return;
  }
  const toolbarJSON = new URLSearchParams(
    window.location.hash.substring(1)
  ).get("__posthog");
  if (toolbarJSON) {
    this.posthog.loadToolbar(JSON.parse(toolbarJSON));
  }
}
