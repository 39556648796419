var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("label",function(){return [(_vm.label)?_c('label',{staticClass:"text-base font-bold",class:{
        'text-error': _vm.failed,
      }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]},{"failed":_vm.failed}),_vm._t("helperText",function(){return [(_vm.helperText)?_c('p',{staticClass:"text-base",class:{
        'text-error': _vm.failed,
      }},[_vm._v(" "+_vm._s(_vm.helperText)+" ")]):_vm._e()]},{"failed":_vm.failed}),_c('div',{staticClass:"flex flex-wrap w-full rounded border border-solid border-gray-300 hover:border-gray-400 focus-within:!border-primary focus-within:shadow-md",class:{ '!bg-gray-100': _vm._disabled }},[_c('div',{staticClass:"relative w-full flex-1"},[_c('IconComponent',{staticClass:"absolute top-1/2 -translate-y-1/2 left-0 ml-2 text-gray-300 placeholder-gray-300 w-6",class:{
          '!ml-4': _vm.size === 'lg',
        },attrs:{"pack":_vm.iconPackLeft,"icon":_vm.iconLeft}}),(_vm.mask)?[_c('input',_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],ref:"input",staticClass:"rounded py-2 box-border w-full focus:outline-none text-gray-700 placeholder-gray-300",class:{
            '!pl-9': _vm.iconLeft && _vm.size !== 'lg',
            '!pl-10': _vm.iconLeft && _vm.size === 'lg',
            '!pl-2': !_vm.iconLeft,
            '!pr-9': _vm.iconRight,
            '!pr-2': !_vm.iconRight,
            'text-right': _vm.type === 'number',
            '!rounded-none': _vm.styling === 'squared',
            'text-2xl py-3': _vm.size === 'lg',
            'rounded-r-none border-r-0': _vm.unit,
            '!bg-gray-100': _vm._disabled,
          },attrs:{"type":_vm.actualType,"placeholder":_vm.placeholder,"disabled":_vm._disabled,"step":_vm.type === 'number' ? _vm.step ?? 1 : null},domProps:{"value":_vm.value},on:{"keypress":(event) => _vm.$emit('keypress', event),"input":_vm.onInput,"focusout":_vm.onFocusOut,"blur":(event) => _vm.$emit('blur', event)}},'input',_vm.$attrs,false))]:[_c('input',_vm._b({ref:"input",staticClass:"rounded py-2 box-border w-full focus:outline-none text-gray-700 placeholder-gray-300",class:{
            '!pl-9': _vm.iconLeft && _vm.size !== 'lg',
            '!pl-10': _vm.iconLeft && _vm.size === 'lg',
            '!pl-2': !_vm.iconLeft,
            '!pr-9': _vm.iconRight,
            '!pr-2': !_vm.iconRight,
            'text-right': _vm.type === 'number',
            '!rounded-none': _vm.styling === 'squared',
            'text-2xl py-3': _vm.size === 'lg',
            'rounded-r-none border-r-0 focus:outline-none': _vm.unit,
            '!bg-gray-100': _vm._disabled,
          },attrs:{"type":_vm.actualType,"placeholder":_vm.placeholder,"disabled":_vm._disabled,"step":_vm.type === 'number' ? _vm.step ?? 1 : null},domProps:{"value":_vm.value},on:{"keypress":(event) => _vm.$emit('keypress', event),"input":_vm.onInput,"focusout":_vm.onFocusOut,"blur":(event) => _vm.$emit('blur', event)}},'input',_vm.$attrs,false))],(_vm.type === 'password')?[(!_vm.revealed)?_c('IconComponent',{staticClass:"absolute top-1/2 -translate-y-1/2 right-0 mr-2 w-6 text-info cursor-pointer",attrs:{"pack":"far","icon":"eye"},nativeOn:{"click":function($event){_vm.revealed = !_vm.revealed}}}):_c('IconComponent',{staticClass:"absolute top-1/2 -translate-y-1/2 right-0 mr-2 w-6 text-info cursor-pointer",attrs:{"pack":"far","icon":"eye-slash"},nativeOn:{"click":function($event){_vm.revealed = !_vm.revealed}}})]:[_c('IconComponent',{staticClass:"absolute top-1/2 -translate-y-1/2 right-0 mr-2 text-gray-300 placeholder-gray-300 w-6",attrs:{"pack":_vm.iconPackRight,"icon":_vm.iconRight}})]],2),(_vm.unit)?_c('span',{staticClass:"flex py-2 pr-2 items-center bg-white text-gray-400 rounded-r",class:{ '!bg-gray-100': _vm.disabled },on:{"click":_vm.focus}},[_vm._v(" "+_vm._s(_vm.unit)+" ")]):_vm._e()]),(_vm.showErrorMsg)?_c('p',{staticClass:"text-sm text-error",class:{
      invisible: !_vm.failed,
    }},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }