<template>
  <ValidationProvider
    ref="validation"
    v-slot="{ errors, invalid, failed }"
    tag="div"
    :rules="rules"
    :vid="vid"
  >
    <slot name="label" :failed="failed" :invalid="invalid">
      <p
        v-if="label"
        class="text-base font-bold"
        :class="{
          'text-error': failed,
        }"
      >
        {{ label }}
      </p>
    </slot>

    <textarea
      ref="description"
      v-model="valueModel"
      :placeholder="placeholder"
      class="w-full h-20 min-h-[20px] shadow-inner border border-solid p-3 focus-visible:border-primary focus-visible:outline-none placeholder:text-gray-400 rounded-md"
      :class="{
        'border-gray-400': !failed,
        'border-error': failed,
        'resize-none': !resizeAble,
      }"
      type="text"
      @click="$emit('click')"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
    <p v-show="failed" class="text-sm text-error">
      {{ errors[0] }}
    </p>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    resizeAble: {
      type: Boolean,
      default: false,
    },
    iconLeft: {
      type: String,
      default: "",
    },
    iconPackLeft: {
      type: String,
      default: "fas",
    },
    iconRight: {
      type: String,
      default: "",
    },
    iconPackRight: {
      type: String,
      default: "fas",
    },
    placeholder: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    immediate: {
      type: Boolean,
      default: false,
      description: "Whether or not input should be validated immediately",
    },
    vid: {
      type: String,
      default: "",
    },
  },
  computed: {
    valueModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    hasIconRight() {
      return this.isFailed() || !!this.iconRight;
    },
  },
  mounted() {
    if (this.immediate) {
      this.$refs.validation.validate();
    }
  },
  methods: {
    // onInput (event) {
    //  console.info(event)
    //   if (event.type === 'insertLineBreak') {

    //   }
    //   const value = event.target.value
    //   this.$emit('input', value)
    // },
    isFailed() {
      const validation = this.$refs.validation;
      return validation?.failed;
    },
    focus() {
      this.$refs.description.focus();
    },
  },
};
</script>
