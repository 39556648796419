import {
  ADMIN_JWT_TOKEN_COOKIE_NAME,
  USER_JWT_TOKEN_COOKIE_NAME,
  COOKIE_MAX_AGE,
} from "../constants";
import errorCodes from "~/helpers/errorCodes";

export function useCookieManager({ $cookie, $helpers }) {
  const domain = $helpers.getMainDomain();

  const typeToCookieName = {
    user: USER_JWT_TOKEN_COOKIE_NAME,
    admin: ADMIN_JWT_TOKEN_COOKIE_NAME,
  };

  function getCookieNameBy(type) {
    const cookieName = typeToCookieName[type];

    if (!cookieName) throw new Error(errorCodes.TOKEN_TYPE_DOES_NOT_EXIST);
    return cookieName;
  }

  function setTokenCookie(token, type = "user") {
    /**
     * If we’d like to allow subdomains like forum.site.com to get a cookie set at site.com, that’s possible.
     * For that to happen, when setting a cookie at site.com, we should explicitly set the domain attribute to the root domain: domain=site.com. This is happening here
     * Then all subdomains will see such a cookie.
     * Please note, by default, a cookie is not shared with a subdomain, such as forum.site.com.
     */
    $cookie.set(getCookieNameBy(type), token, {
      maxAge: COOKIE_MAX_AGE,
      domain,
      path: "/",
    });
  }
  function setTokenCookieForAll(token) {
    setTokenCookie(token, "user");
    setTokenCookie(token, "admin");
  }
  function clearTokenCookie(type = "user") {
    $cookie.remove(getCookieNameBy(type), {
      domain,
      path: "/",
    });
  }

  function clearTokenCookieForAll() {
    clearTokenCookie("user");
    clearTokenCookie("admin");
  }

  function getCampaignsCookie() {
    return $cookie.get("campaigns");
  }

  function clearCampaignsCookie() {
    return $cookie.remove("campaigns");
  }

  function getTokenCookie(type = "user") {
    return $cookie.get(getCookieNameBy(type));
  }

  return {
    setTokenCookie,
    setTokenCookieForAll,
    clearTokenCookie,
    clearTokenCookieForAll,
    getCampaignsCookie,
    clearCampaignsCookie,
    getTokenCookie,
  };
}
