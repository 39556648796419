<template>
  <div
    class="p-4 border-l-4 border-solid font-bold"
    :class="{
      'border-info bg-info/20 text-info': type === 'info',
      'border-success bg-success/20 text-success': type === 'success',
      'border-warning bg-warning/20 text-warning': type === 'warning',
      'border-danger bg-danger/20 text-danger': type === 'danger',
      'border-error bg-error/20 text-error': type === 'error',
    }"
  >
    <slot>
      {{ message }}
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "info",
      validator: function (val) {
        return ["info", "success", "warning", "danger", "error"].includes(val);
      },
    },
  },
};
</script>
