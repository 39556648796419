var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rounded-md",class:{
    '!rounded-none': _vm.styling === 'squared',
  }},[_vm._t("label",function(){return [(_vm.label)?_c('p',{staticClass:"text-base font-bold",class:{
        'text-error': _vm.failed,
      }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]},{"failed":_vm.failed}),_vm._t("helperText",function(){return [(_vm.helperText)?_c('p',{staticClass:"text-base text-gray-500 mb-2",class:{
        'text-error': _vm.failed,
      }},[_vm._v(" "+_vm._s(_vm.helperText)+" ")]):_vm._e()]},{"failed":_vm.failed}),_c('textarea',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.valueModel),expression:"valueModel"}],ref:"description",staticClass:"w-full min-h-[2.5rem] border border-solid p-3 hover:border-gray-400 focus-visible:border-primary focus-visible:outline-none focus-visible:shadow-md placeholder:text-gray-400 rounded-md",class:{
      'border-gray-300': !_vm.failed,
      'border-error': _vm.failed,
      'resize-none': !_vm.resizeAble,
      '!rounded-none': _vm.styling === 'squared',
      'h-48': !_vm.autoSize,
    },attrs:{"placeholder":_vm.placeholder,"disabled":_vm._disabled},domProps:{"value":(_vm.valueModel)},on:{"click":function($event){return _vm.$emit('click')},"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"input":function($event){if($event.target.composing)return;_vm.valueModel=$event.target.value}}},'textarea',_vm.$attrs,false)),_vm._v(" "),(_vm.showErrorMsg)?_c('p',{staticClass:"text-sm text-error",class:{
      invisible: !_vm.failed,
    }},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }