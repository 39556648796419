import Vue from "vue";

/**
 * The ESLint rule vue/no-v-html disallows the use of v-html to prevent XSS
 * attacks via injecting potentially unsafe, unescaped HTML into the browser.
 *
 * This directive, inspired by React's dangerouslySetInnerHTML attribute
 * mitigating the same problem, is made available on all components.
 *
 * Setting HTML directly is therefore possible, but developers have to type out
 * dangerouslySetInnerHTML to remind themselves that it is dangerous.
 */
export default () => {
  Vue.directive("dangerouslySetInnerHTML", function (element, binding) {
    element.innerHTML = binding.value;
  });
};
