import { USER_JWT_TOKEN_COOKIE_NAME } from "./constants";

export function authMiddleware({ route, redirect, app, localePath, $logger }) {
  const hasToken =
    app.$cookie.get(USER_JWT_TOKEN_COOKIE_NAME) || route.query.admin_token;

  // If the user is not authenticated
  if (!hasToken) {
    const uri = encodeURIComponent(route.fullPath);
    $logger.debug("Auth redirecting", { redirectTo: route.fullPath });

    const query = { "callback-url": uri };
    if (route.query.email) {
      query.email = route.query.email;
    }
    const path = localePath({
      name: "login",
      query,
    });
    // Does not work with 401 code
    return redirect(path);
  }
}
