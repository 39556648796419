import { arraySimDiff } from "~/helpers/generic";

const initialFilters = () => {
  return {
    rating: [],
    distance: null,
    priceCategory: [],
    availability: [],
    minRatingCount: null,
  };
};

const PRICE_FILTERS = {
  low: 1,
  medium: 2,
  high: 3,
};

const AVAILABILITY_OPTIONS = {
  nextDays: 1,
  nextWeeks: 2,
  nextMonths: 3,
};

const MIN_RATING_COUNT = 10;

const availabilityCompareFn = (a, b) => {
  return AVAILABILITY_OPTIONS[a] - AVAILABILITY_OPTIONS[b];
};

const priceCategoryCompareFn = (a, b) => {
  return PRICE_FILTERS[a] - PRICE_FILTERS[b];
};

export const state = () => {
  return {
    // Filters that have been explicitly activated by the user
    activeFilters: initialFilters(),
    // Filters being manipulated in the UI
    filters: initialFilters(),
    applyMinRatingCount: false,
  };
};

export const getters = {
  ratingFilter: (state) => state.filters.rating,
  distanceFilter: (state) => state.filters.distance,
  priceCategoryFilter: (state) => state.filters.priceCategory,
  availabilityFilter: (state) => state.filters.availability,
  minRatingCount: (state) => state.filters.minRatingCount,
  applyMinRatingCount: (state) => state.applyMinRatingCount,
  fixedMinRatingCount() {
    return MIN_RATING_COUNT;
  },
  priceCategories() {
    return PRICE_FILTERS;
  },
  activeFilters: (state) => state.activeFilters,
  selectableRatings() {
    return [5, 4.5, 4, 3.5, 3];
  },
  selectableAvailabilities() {
    return AVAILABILITY_OPTIONS;
  },
  filtersChanged({ filters, activeFilters }) {
    // const initial = initialFilters()
    return {
      rating: !!arraySimDiff(filters.rating, activeFilters.rating).length, // && !!arraySimDiff(filters.rating, initial.rating).length,
      priceCategory: !!arraySimDiff(
        filters.priceCategory,
        activeFilters.priceCategory
      ).length, // && !!arraySimDiff(filters.priceCategory, initial.priceCategory).length,
      distance: filters.distance !== activeFilters.distance, // && filters.distance !== initial.distance,
      availability: !!arraySimDiff(
        filters.availability,
        activeFilters.availability
      ).length, // && !!arraySimDiff(filters.availability, initial.availability).length
    };
  },
  activeFilterCount(state) {
    // TODO not reactive
    // let count = 0
    // if (state.activeFilters.rating.length) { count++ }
    // if (state.activeFilters.distance > 0) { count++ }
    // if (state.activeFilters.priceCategory.length) { count++ }

    return state.activeFilters.distance > 0 ? 1 : 0;
  },
  analyticsValues({ filters }) {
    // e.g. ['low','high'] => [1,3] => 13
    const priceCatIntValues = filters.priceCategory.map(
      (x) => PRICE_FILTERS[x]
    );
    // const priceCategory = priceCatIntValues.length
    //   ? +priceCatIntValues.join('')
    //   : null

    // e.g. ['nextDays','nextWeeks'] => [1,2] => 12
    const availabilityIntValues = filters.availability.map(
      (x) => AVAILABILITY_OPTIONS[x]
    );
    // const availability = availabilityIntValues.length
    //   ? +availabilityIntValues.join('')
    //   : null

    return {
      priceCategory: +priceCatIntValues.join(""),
      availability: +availabilityIntValues.join(""),
      rating: +filters.rating.map((x) => x * 2).join(""),
    };
  },
};

export const mutations = {
  setFilters(state, filters) {
    const { priceCategory, rating, distance, availability } = filters;
    if (
      priceCategory === undefined &&
      rating === undefined &&
      distance === undefined &&
      availability === undefined
    ) {
      throw new Error("invalid action parameters");
    }
    state.filters = {
      ...state.filters,
      ...filters,
    };
    state.filters.availability.sort(availabilityCompareFn);
    state.filters.priceCategory.sort(priceCategoryCompareFn);
    state.filters.rating.sort((a, b) => a - b);
  },
  selectPriceCategory(state, priceCategory) {
    if (state.filters.priceCategory.includes(priceCategory)) {
      state.filters.priceCategory = state.filters.priceCategory.filter(
        (x) => x !== priceCategory
      );
    } else {
      state.filters.priceCategory.push(priceCategory);
    }
  },
  applyFilters(state) {
    state.activeFilters = Object.assign({}, state.filters);
  },
  resetFilters(state) {
    state.filters = initialFilters();
    state.activeFilters = initialFilters();
  },
  setApplyMinRatingCount(state, value) {
    state.applyMinRatingCount = value;
    if (state.applyMinRatingCount) {
      state.filters.minRatingCount = MIN_RATING_COUNT;
    } else {
      state.filters.minRatingCount = null;
    }
  },
};

export const actions = {
  setFilters({ commit }, filters) {
    commit("setFilters", filters);
  },
  applyFilters({ commit }) {
    commit("applyFilters");
  },
  resetFilters({ commit }) {
    commit("resetFilters");
  },
  selectPriceCategory({ commit }, priceCategory) {
    commit("selectPriceCategory", priceCategory);
  },
  setApplyMinRatingCount({ commit }, value) {
    commit("setApplyMinRatingCount", value);
  },
};
