<template>
  <section
    class="JoszakiCard max-lg:p-3 lg:p-4 rounded-md shadow-md border border-gray-300"
    :class="{
      'hover:shadow-xl': hoverable,
      'bg-white': 'default' === type,
      'bg-info text-white': 'info' === type,
      'bg-primary text-white': 'primary' === type,
      'bg-success text-white': 'success' === type,
      'bg-warning': 'warning' === type,
      'bg-danger text-white': 'danger' === type,
      'bg-error': 'error' === type,
      'bg-rating': 'rating' === type,
    }"
  >
    <slot />
  </section>
</template>

<script>
export default {
  name: "JoszakiCard",
  props: {
    type: {
      type: String,
      default: "default",
      validator: function (val) {
        return [
          "default",
          "info",
          "primary",
          "success",
          "warning",
          "danger",
          "error",
          "rating",
        ].includes(val);
      },
    },
    hoverable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
