export const EVENT_PAGE_NAMES = {
  PROFESSION_CITY_PAGE: "Profession-city page",
  PROFESSION_COUNTY_PAGE: "Profession-county page",
  PROFESSIONS_PAGE: "Professions page",
  PROFESSIONAL_PAGE: "Professional page",
  PROFESSIONS_LANDING_PAGE: "Professions Landing Page",
  PROFESSION_GROUP_PAGE: "Profession Group Page",
  CITY_PAGE: "City page",
  COUNTY_PAGE: "County page",
  NEW_PROFESSIONAL_PAGE: "New professional page",
  QUESTIONS_LANDING_PAGE: "Questions landing page",
  QUESTION_ANSWER_PAGE: "Question-answer page",
  QUESTION_NEW_PAGE: "New Question page",
  SEARCH_PAGE: "Search page",
  PRICES_PAGE: "Prices page",
  PRICE_CALCULATOR_PAGE: "Price calculator page",
  REQUEST_TENDER_PAGE: "Request tender page",
  TENDER_SPECIFICATION_PAGE: "Tender specification page",
  HOME_PAGE: "Home page",
  TOP_UP_PAGE: "Top up page",
  SETTINGS_PAGE: "Settings page",
  SUBSCRIPTION_PAGE: "Subscription page",
  SUBSCRIPTION_PAGE_2: "Subscription page 2",
  REGISTRATION_PAGE: "Registration page",
  INSPIRATION_LANDING_PAGE: "Inspiration landing page",
  INSPIRATION_PROFESSION_TASK_PAGE: "Inspiration profession task page",
  INSPIRATION_PROFESSION_PAGE: "Inspiration profession page",
  INSPIRATION_PAGE: "Inspiration page",
  INSPIRATION_PICTURE_PAGE: "Inspiration picture page",
  SALARIES_PAGE: "Salaries page",
  EMAIL_CONFIRM_PAGE: "Email confirm page",
  REFERENCES_PAGE: "References page",
  MY_TENDERS_PAGE: "My tenders page",
  BLOG_ARTICLE_PAGE: "Blog article page",
  BLOG_LIST_PAGE: "Blog list page",
  BLOG_LIST_PAGE_BY_COLLECTION: "Blog list page by collection",
  BLOG_LIST_PAGE_BY_PROFESSION: "Blog list page by profession",
  BLOG_LIST_PAGE_BY_PROFESSION_TASK: "Blog list page by profession task",
  BLOG_LIST_PAGE_BY_TAG: "Blog list page by tag",
};
