<template>
  <div
    class="bg-gray-300/20 shadow-md border-2 border-dashed border-gray-500 rounded-md cursor-pointer hover:bg-primary/5 hover:border-primary"
    @drop.prevent="onDrop"
    @dragenter.prevent="onDragEnter"
    @dragover.prevent
    @dragleave.prevent="onDragLeave"
  >
    <slot name="drop" />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    accept: {
      type: String,
      default: "*",
    },
  },
  data() {
    return {
      dragging: false,
    };
  },
  computed: {},
  methods: {
    onDragEnter(event) {
      this.dragging = true;
      event.dataTransfer.dropEffect = "copy";
      event.currentTarget.classList.add("bg-primary/5", "border-primary");
    },
    onDragLeave(event) {
      this.dragging = false;
      event.currentTarget.classList.remove("bg-primary/5", "border-primary");
    },
    onDrop(event) {
      const files = event.dataTransfer.files;

      const hasLargeFile = Array.from(files)
        .map((file) => file.size)
        .some((size) => size > process.env.MAX_UPLOAD_SIZE_IN_MB * 1000 * 1000);

      if (hasLargeFile) {
        this.$joszaki.toast({
          message: this.$t("uploader.maxFileSize", {
            maxSize: process.env.MAX_UPLOAD_SIZE_IN_MB,
          }),
          type: "error",
        });

        return;
      }

      if (this.accept === "*") {
        this.$emit("upload", files);
        return;
      }

      const acceptedTypes = this.accept.split(",").map((type) => type.trim());
      const hasInvalidType = Array.from(files).some((file) => {
        return (
          !acceptedTypes.includes(file.type) &&
          !acceptedTypes.some(
            (type) =>
              type.endsWith("/*") && file.type.startsWith(type.slice(0, -1))
          )
        );
      });

      if (hasInvalidType) {
        this.$joszaki.toast({
          message: this.$t("uploader.invalidFileType"),
          type: "error",
        });

        return;
      }

      this.$emit("upload", files);
    },
  },
};
</script>
