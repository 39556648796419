<template>
  <JoszakiAutocomplete
    v-model="miniMarketValue"
    v-bind="$attrs"
    icon="map-marked-alt"
    :items="filteredMiniMarkets"
    :formatter="(item) => `${item.profession.name} - ${item.name}`"
    :placeholder="value?.name ?? '-'"
    :loading="loading"
    @input="onInput"
    @focus="onFocus"
    @blur="onBlur"
    @select="onSelect"
  >
    <template #empty>
      {{ $t("conductor.common.noResults") }}
    </template>
  </JoszakiAutocomplete>
</template>

<script>
import { ref } from "@nuxtjs/composition-api";
import { debounce } from "~/helpers/debounce";
import { useMiniMarketActions } from "~/components/_refactored/conductor/mini-markets/useMiniMarketActions";

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { getMiniMarkets } = useMiniMarketActions();
    const filteredMiniMarkets = ref([]);
    const miniMarketFilter = ref("");
    const miniMarketValue = ref(props.value?.name ?? "-");
    const focused = ref(false);
    const loading = ref(false);

    const onInput = debounce(async function (search) {
      loading.value = true;
      const { items } = await getMiniMarkets({
        search,
        page: 1,
        pageSize: 15,
      });
      filteredMiniMarkets.value = items;
      loading.value = false;
    }, 500);

    return {
      filteredMiniMarkets,
      miniMarketFilter,
      miniMarketValue,
      onInput,
      focused,
      loading,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.miniMarketValue = newValue.name;
        }
      },
    },
  },
  methods: {
    onFocus() {
      this.focused = true;
      this.miniMarketValue = this.miniMarketFilter;
      this.onInput(this.miniMarketFilter);
      this.$emit("focus");
    },
    onBlur() {
      this.focused = false;
      this.miniMarketFilter = this.miniMarketValue;
      this.miniMarketValue = this.value?.name ?? "-";
      this.$emit("blur");
    },
    onSelect(team) {
      this.miniMarketFilter = "";
      if (team) {
        this.$emit("select", team);
      } else {
        this.onBlur();
      }
    },
  },
};
</script>
