import { useContext } from "@nuxtjs/composition-api";
import { parseJwt } from "../helpers";
import errorCodes from "~/helpers/errorCodes";

/**
 * TODO should be removed if we fix this error
 */
export function useCheckMismatch(context) {
  const { $logger } = context || useContext();

  function checkMismatch(personId, token) {
    const parsedToken = parseJwt(token);
    // eslint-disable-next-line eqeqeq
    if (personId != parsedToken.id) {
      const error = new Error(errorCodes.LOGIN_MISMATCH);
      error.personId = personId;
      error.parsedTokenId = parsedToken.id;
      error.level = "fatal";

      // TODO log thrown errors globally, with error level
      $logger.fatal(errorCodes.LOGIN_MISMATCH, error);
    }
  }

  return checkMismatch;
}
