import { defineStore, storeToRefs } from "pinia";
import Vue from "vue";
import dayjs from "dayjs";
import LAST_PAYMENT from "~/stores/user/lastPayment.graphql";
import PRICES_AND_PROFESSION_TASKS from "@/graphql/pricesAndProfessionTasks.graphql";
import TENDERS_FROM_ME_QUERY from "@/graphql/tendersFromMeQuery.graphql";
import LAST_PAID_SUBSCRIPTIONS_QUERY from "@/graphql/lastPaidSubscriptions.graphql";
import CREATE_OR_UPDATE_PRICE from "@/graphql/mutations/createOrUpdatePrice.graphql";
import DELETE_PRICE from "@/graphql/mutations/deletePrice.graphql";
import ME_QUERY from "@/graphql/me.graphql";
import SET_PERSON_GO_TO_HOUSE from "@/graphql/profile/setPersonGoToHouse.graphql";
import CHANGE_PASSWORD from "@/graphql/profile/changePassword.graphql";
import SET_PERSON_HIDE_MOBILE from "@/graphql/profile/setPersonHideMobile.graphql";

import PAYMENT from "@/graphql/payment.graphql";
import { useAdminUserSwitcher } from "~/components/_refactored/admin";

export const useUserStore = defineStore("userStore", {
  state: () => ({
    me: null,
    meQuery: null,
    meLoading: false,
    meLoaded: false,
    showTenderDate: false,
    isSettingsSame: true,
    loading: false,
  }),
  getters: {
    subscribedQuestionIds: (state) =>
      (state.me?.questionSubscriptions ?? []).map((question) => question.id),
    freeSubs: (state) => {
      let res = [];
      if (state.me?.validSubscriptions) {
        res = state.me.validSubscriptions.filter((e) => e.isFree);
      }
      return res;
    },
    hasPaidSubscription: (state) =>
      state?.me?.nonExpiredPaidSubscriptions?.length > 0,
    pendingPayment: (state) =>
      state.me?.lastPayment?.status === "started" &&
      state.me?.lastPayment?.isSubscription,
    pendingCardReg: (state) =>
      state.me?.lastPayment?.status === "started" &&
      state.me?.lastPayment?.isCardRegistration,
    failedPayment: (state) =>
      state.me?.lastPayment?.status === "error" &&
      state.me?.lastPayment?.isSubscription,
    failedCardReg: (state) =>
      state.me?.lastPayment?.status === "error" &&
      state.me?.lastPayment?.isCardRegistration,
    isAdmin: (state) => state.me?.isAdmin,
    lastPayment: (state) => state.me?.lastPayment,
    prices: (state) => state.me?.prices ?? [],
    paymentBlocked() {
      return this.pendingPayment || this.pendingCardReg;
    },
    paymentFailed() {
      return (
        this.pendingPayment ||
        this.failedPayment ||
        this.pendingCardReg ||
        this.failedCardReg
      );
    },
    paymentFailedReason() {
      if (this.failedPayment) {
        return "announcements.paymentFailed";
      } else if (this.failedCardReg) {
        return "announcements.cardRegFailed";
      } else if (this.pendingPayment) {
        return "announcements.paymentPending";
      } else if (this.pendingCardReg) {
        return "announcements.cardRegPending";
      }
    },
    validSubscriptions() {
      return this.me?.validSubscriptions ?? [];
    },
  },
  actions: {
    updateLastPerson() {
      this.$nuxt.$logger.debug("updateLastPerson");
      if (this.me?.isAdmin) {
        this.$nuxt.$logger.debug("is admin, skipping updateLastPerson");
        return;
      }
      const { switchedToMate } = storeToRefs(useAdminUserSwitcher());
      if (switchedToMate.value) {
        this.$nuxt.$logger.debug("switchedToMate skipping updateLastPerson");
        return;
      }

      let lastPerson;
      try {
        lastPerson = JSON.parse(localStorage.getItem("_lp") || null);
      } catch (error) {}
      if (
        Array.isArray(lastPerson) &&
        !lastPerson.includes(this.me.lastPerson)
      ) {
        lastPerson.push(this.me.lastPerson);
        localStorage.setItem("_lp", JSON.stringify(lastPerson));
      } else if (!Array.isArray(lastPerson)) {
        if (!lastPerson || lastPerson === this.me.lastPerson) {
          localStorage.setItem("_lp", JSON.stringify([this.me.lastPerson]));
        } else if (lastPerson !== this.me.lastPerson) {
          localStorage.setItem(
            "_lp",
            JSON.stringify([lastPerson, this.me.lastPerson])
          );
        }
      }
    },
    async queryMe() {
      try {
        console.debug("queryMe");
        if (this.meQuery) {
          // repeat calls should wait for finish
          await this.meQuery;
          return;
        }
        this.meLoading = true;
        this.meQuery = this.$nuxt.$query(ME_QUERY);
        const result = await this.meQuery;
        this.me = result.me;
        this.meLoaded = true;
        return Promise.resolve(true);
      } catch (error) {
        console.error("refetch error", error);
        return Promise.reject(error);
      } finally {
        this.meLoading = false;
        this.meQuery = null;
      }
    },
    setUserValues(payload) {
      if (!this.me) {
        this.me = payload;
        return;
      }
      for (const key of Object.keys(payload)) {
        if (key.startsWith("__")) {
          // Ignore graphql types
          continue;
        }
        Vue.set(this.me, key, payload[key]);
      }
    },
    deletePriceById(priceId) {
      this.me.prices = this.me.prices.filter((pr) => {
        // eslint-disable-next-line eqeqeq
        return pr.id != priceId;
      });
    },
    //
    async createOrUpdatePrice(variables) {
      try {
        const result = await this.$nuxt.$mutate(
          CREATE_OR_UPDATE_PRICE,
          variables
        );
        if (variables.id) {
          this.deletePriceById(variables.id);
        }
        this.me.prices.push(result.price);
        return Promise.resolve(result.me);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async deletePrice(priceId) {
      try {
        const variables = {
          priceId,
        };
        await this.$nuxt.$mutate(DELETE_PRICE, variables);
        this.deletePriceById(priceId);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    // My Prices
    async getPrices() {
      try {
        const result = await this.$nuxt.$query(PRICES_AND_PROFESSION_TASKS);
        // Why thou?
        if (!result) {
          console.error("Empty result getPrices", result);
          return;
        }
        this.setUserValues(result.me);
        return Promise.resolve(result.me);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async loadPayment(variables = {}) {
      try {
        const result = await this.$nuxt.$query(PAYMENT, variables);
        // Why thou?
        if (!result) {
          console.error("Empty result loadPayment", result);
          return;
        }
        this.setUserValues(result.me);
        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async loadLastPayment() {
      try {
        if (this.me) {
          const result = await this.$nuxt.$query(LAST_PAYMENT);
          this.setUserValues(result.me);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    },

    setShowTenderDate(value) {
      this.showTenderDate = value === "true";
    },

    // My tenders
    async getMyTenderFeed() {
      try {
        const result = await this.$nuxt.$query(TENDERS_FROM_ME_QUERY);
        // Why thou?
        if (!result) {
          console.error("Empty result getMyTenderFeed", result);
          return;
        }
        this.setUserValues(result.me);
        return Promise.resolve(result.me.tenders);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    // My tenders
    async getMySubscription(variables) {
      try {
        const result = await this.$nuxt.$query(
          LAST_PAID_SUBSCRIPTIONS_QUERY,
          variables
        );
        // Why thou?
        if (!result) {
          console.error("Empty result getMySubscription", result);
          return;
        }
        this.setUserValues(result.me);
        return Promise.resolve(result.me);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async refetch(variables = {}) {
      try {
        const result = await this.$nuxt.$query(ME_QUERY, variables);
        this.setUserValues(result.me);
        return Promise.resolve(true);
      } catch (error) {
        console.error("refetch error", error);
        return Promise.reject(error);
      }
    },

    async updateUnavailability(availability) {
      const now = dayjs().toISOString();
      const xDaysFromNow = (x) => dayjs().add(x, "day").toISOString();

      const payload = {
        from: now,
        until: xDaysFromNow(availability.daysToAdd),
        trust_date: xDaysFromNow(availability.trustDays),
      };

      try {
        const SET_UNAVAILABILITY = await import("./setUnavailability.graphql");
        const resp = await this.$nuxt.$mutate(SET_UNAVAILABILITY, {
          input: {
            ...payload,
          },
        });
        this.me = {
          ...this.me,
          lastUnavailability: resp.personUnavailability,
        };
      } catch (error) {
        return Promise.reject(error);
      }
    },

    updateMyTender(tender) {
      const index = this.me.tenders.findIndex((t) => {
        return t.id === tender.id;
      });
      if (index < 0) {
        console.error("Cant find tender to update");
        return;
      }
      const updated = {
        ...this.me.tenders[index],
        ...tender,
      };
      Vue.set(this.me.tenders, index, updated);
    },

    /**
     * @param {*} value Boolean from the b-switch
     * @returns
     */
    async setHasUnavailability(value) {
      try {
        const SET_HAS_UNAVAILABILITY = await import(
          "./setHasUnavailability.graphql"
        );
        const resp = await this.$nuxt.$mutate(SET_HAS_UNAVAILABILITY, {
          input: value,
        });

        this.me = {
          ...this.me,
          hasUnavailability: resp,
        };
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async loadPhotos() {
      try {
        if (!this.meLoaded) {
          await this.queryMe();
        }
        const LOAD_PHOTOS = await import("./referencePhotos.graphql");
        const resp = await this.$nuxt.$query(LOAD_PHOTOS);
        this.me = {
          ...this.me,
          ...resp.me,
        };
      } catch (error) {
        console.error("loadPhotos error", error);
        return Promise.reject(error);
      }
    },

    async uploadPhotos(photos, profession) {
      try {
        const UPLOAD_PHOTOS = await import("./uploadReferencePhoto.graphql");
        for (const photo of photos) {
          const resp = await this.$nuxt.$mutate(UPLOAD_PHOTOS, {
            file: photo,
            professionId: profession?.id ?? null,
          });
          this.me.referencePhotos.push(resp.referencePhoto);
        }
      } catch (error) {
        console.error("uploadPhotos error", error);
        return Promise.reject(error);
      }
    },

    async updatePhoto(photoId, note) {
      try {
        const UPDATE_PHOTO = await import("./updateReferencePhoto.graphql");
        const resp = await this.$nuxt.$mutate(UPDATE_PHOTO, {
          photoId,
          note,
        });
        this.me.referencePhotos = this.me.referencePhotos.map((p) => {
          if (p.id === photoId) {
            return resp.referencePhoto;
          }
          return p;
        });
        return Promise.resolve(resp.referencePhoto);
      } catch (error) {
        console.error("updatePhoto error", error);
        return Promise.reject(error);
      }
    },

    async deletePhoto(photoId) {
      try {
        const DELETE_PHOTO = await import("./deleteReferencePhoto.graphql");
        await this.$nuxt.$mutate(DELETE_PHOTO, {
          photoId,
        });
        this.me.referencePhotos = this.me.referencePhotos.filter((p) => {
          return p.id !== photoId;
        });
      } catch (error) {
        console.error("deletePhoto error", error);
        return Promise.reject(error);
      }
    },

    deleteScoreAnswer({ answerId }) {
      this.me.scores.find((s) => s.answer?.id === answerId).answer = null;
    },

    setScoreAnswer({ scoreId, answer }) {
      this.me.scores.find((s) => s.id === scoreId).answer = answer;
    },

    removeQuestionSubscription(questionId) {
      this.me.questionSubscriptions = this.me.questionSubscriptions.filter(
        (question) => question.id !== questionId
      );
    },

    addQuestionSubscription(questionId) {
      this.me.questionSubscriptions.push({ id: questionId });
    },

    setIsSettingsSame(isSame) {
      this.isSettingsSame = isSame;
    },

    setHasPassword(hasPassword) {
      if (!this.me) {
        return;
      }
      this.me.hasPassword = hasPassword;
    },

    async selectTenderWinnder({ tenderId, winnerPersonTenderId }) {
      try {
        const CLOSE_TENDER = await import("./closeTender.graphql");
        const resp = await this.$nuxt.$mutate(CLOSE_TENDER, {
          tenderId,
          winnerPersonTenderId,
          closeReason: "won",
        });
        this.me.tenders = this.me.tenders.map((t) => {
          if (t.id === tenderId) {
            return {
              ...t,
              ...resp.tender,
            };
          }
          return t;
        });
        return Promise.resolve(resp.closeTender);
      } catch (error) {
        this.$nuxt.$sentry.captureException(error, {
          tags: {
            action: "selectTenderWinnder",
            store: "userStore",
          },
        });
        return Promise.reject(error);
      }
    },

    async closeTender(tenderId) {
      try {
        const CLOSE_TENDER = await import("./closeTender.graphql");
        const resp = await this.$nuxt.$mutate(CLOSE_TENDER, {
          tenderId,
          closeReason: "outdated",
        });
        this.me.tenders = this.me.tenders.map((t) => {
          if (t.id === tenderId) {
            return {
              ...t,
              ...resp.tender,
            };
          }
          return t;
        });
        return Promise.resolve(resp.closeTender);
      } catch (error) {
        this.$nuxt.$sentry.captureException(error, {
          tags: {
            action: "closeTender",
            store: "userStore",
          },
        });
        console.error("closeTender error", error);
        return Promise.reject(error);
      }
    },

    async uploadProfilePicture(file) {
      try {
        const UPLOAD_PROFILE_PICTURE = await import(
          "./uploadProfilePicture.graphql"
        );
        const resp = await this.$nuxt.$mutate(UPLOAD_PROFILE_PICTURE, {
          personId: this.me.id,
          file,
        });
        this.me = {
          ...this.me,
          name: resp.person.name,
          hasProfilePicture: resp.person.hasProfilePicture,
        };
      } catch (error) {
        this.$nuxt.$sentry.captureException(error, {
          tags: {
            action: "uploadProfilePicture",
            store: "userStore",
          },
        });
        console.error("uploadProfilePicture error", error);
        return Promise.reject(error);
      }
    },

    async setName(name) {
      try {
        const SET_PERSON_NAME = await import("./setPersonName.graphql");
        const resp = await this.$nuxt.$mutate(SET_PERSON_NAME, {
          personId: this.me.id,
          name,
        });
        this.me = {
          ...this.me,
          name: resp.person.name,
        };
      } catch (error) {
        this.$nuxt.$sentry.captureException(error, {
          tags: {
            action: "setName",
            store: "userStore",
          },
        });
        console.error("setName error", error);
        return Promise.reject(error);
      }
    },

    async setEmail(email) {
      try {
        const SETPERSONEMAIL = await import("./setPersonEmail.graphql");
        const resp = await this.$nuxt.$mutate(SETPERSONEMAIL, {
          personId: this.me.id,
          email,
        });
        this.me = {
          ...this.me,
          email: resp.person.email,
        };
      } catch (error) {
        this.$nuxt.$sentry.captureException(error, {
          tags: {
            action: "setEmail",
            store: "userStore",
          },
        });
        console.error("setEmail error", error);
        return Promise.reject(error);
      }
    },

    async setPhone({ number, countryCodeId }) {
      try {
        const SET_PERSON_MOBILE = await import("./setPersonMobile.graphql");
        const resp = await this.$nuxt.$mutate(SET_PERSON_MOBILE, {
          personId: this.me.id,
          number,
          countryCodeId,
        });
        this.me = {
          ...this.me,
          phoneNumber: resp.phoneNumber,
        };
      } catch (error) {
        this.$nuxt.$sentry.captureException(error, {
          tags: {
            action: "setPhone",
            store: "userStore",
          },
        });
        console.error("setPhone error", error);
        return Promise.reject(error);
      }
    },

    async setPersonGoToHouse({ goToHouse, goToHouseKm }) {
      try {
        this.loading = true;
        await this.$nuxt.$mutate(SET_PERSON_GO_TO_HOUSE, {
          personId: this.me.id,
          goToHouse,
          goToHouseKm,
        });
      } catch (error) {
        this.$nuxt.$logger.error(error, "setPersonGoToHouse");
        return Promise.reject(error);
      } finally {
        this.loading = false;
      }
    },

    async changePassword({ currentPassword, newPassword }) {
      try {
        this.loading = true;
        await this.$nuxt.$mutate(CHANGE_PASSWORD, {
          personId: this.me.id,
          currentPassword,
          newPassword,
        });
      } catch (error) {
        this.$nuxt.$logger.error(error, "changePassword");
        return Promise.reject(error);
      } finally {
        this.loading = false;
      }
    },

    async setHideMobile(value) {
      const variables = {
        personId: this.me.id,
        hideMobile: value,
      };
      try {
        this.loading = true;
        await this.$nuxt.$mutate(SET_PERSON_HIDE_MOBILE, variables);
        this.me.hideMobile = value;
      } catch (error) {
        return Promise.reject(error);
      } finally {
        this.loading = false;
      }
      return null;
    },
  },
});
