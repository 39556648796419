import { useContext } from "@nuxtjs/composition-api";
import PROFESSION_TASKS from "@/graphql/professionTasks.graphql";
import useRestClient from "~/helpers/restClient";

export function useProfessionTaskQueries() {
  const { $query, $api } = useContext();

  const restClient = useRestClient($api);

  async function fetchProfessionTasks(filters) {
    const { professionTasks } = await $query(PROFESSION_TASKS, {
      filters,
    });
    return professionTasks;
  }

  async function fetchProfessionTasksByKeyword(keyword) {
    const response = await restClient.professionTasks.searchByKeyword(keyword);
    return response.map((task) => ({
      id: task.id,
      name: task.name,
      seoName: task.seoName,
    }));
  }

  return {
    fetchProfessionTasks,
    fetchProfessionTasksByKeyword,
  };
}
