var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{ref:"validation",attrs:{"tag":"div","rules":_vm.rules,"vid":_vm.vid},scopedSlots:_vm._u([{key:"default",fn:function({ errors, invalid, failed }){return [_vm._t("label",function(){return [(_vm.label)?_c('p',{staticClass:"text-base font-bold",class:{
        'text-error': failed,
      }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]},{"failed":failed,"invalid":invalid}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.valueModel),expression:"valueModel"}],ref:"description",staticClass:"w-full h-20 min-h-[20px] shadow-inner border border-solid p-3 focus-visible:border-primary focus-visible:outline-none placeholder:text-gray-400 rounded-md",class:{
      'border-gray-400': !failed,
      'border-error': failed,
      'resize-none': !_vm.resizeAble,
    },attrs:{"placeholder":_vm.placeholder,"type":"text"},domProps:{"value":(_vm.valueModel)},on:{"click":function($event){return _vm.$emit('click')},"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"input":function($event){if($event.target.composing)return;_vm.valueModel=$event.target.value}}}),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"text-sm text-error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }