<template>
  <b-field
    :type="failed ? 'is-danger' : ''"
    :message="failed ? errorMessage : ''"
  >
    <b-checkbox
      v-model="checkBoxValue"
      :type="failed ? 'is-danger' : ''"
      :disabled="_disabled"
    >
      <slot name="label">
        <span v-if="label">
          {{ label }}
        </span>
      </slot>
    </b-checkbox>
  </b-field>
</template>

<script>
import { inject } from "@nuxtjs/composition-api";

export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      required: true,
    },
    validationState: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  setup() {
    const { disabled: formDisabled } = inject("form", { disabled: false });
    return {
      formDisabled,
    };
  },
  computed: {
    _disabled() {
      return this.disabled || this.formDisabled;
    },
    errorMessage() {
      return this.validationState?.$errors?.[0]?.$message;
    },
    failed() {
      return this.validationState?.$error;
    },
    checkBoxValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
