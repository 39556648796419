import Vue from "vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";

import {
  required,
  email,
  min,
  confirmed,
  // eslint-disable-next-line camelcase
  alpha_spaces,
  regex,
  mimes,
  oneOf,
  // eslint-disable-next-line camelcase
  min_value,
  max,
  integer,
} from "vee-validate/dist/rules";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
const MOBILE_PREFIXES = ["20", "21", "30", "31", "50", "70"];

extend("required", required);
extend("email", email);
extend("min", min);
extend("max", max);
extend("confirmed", confirmed);
extend("alpha_spaces", alpha_spaces);
extend("regex", regex);
extend("integer", integer);
extend("mobile", regex);
extend("mimes", mimes);
extend("agree", required);
extend("oneOf", oneOf);
extend("min_price", min_value);
extend("mobile_prefix", (value) => {
  const toCheck = value.startsWith("(")
    ? value.substr(1, 2)
    : value.substr(0, 2);
  return MOBILE_PREFIXES.includes(toCheck);
});

export default function ({ app }) {
  configure({
    defaultMessage: (_field, values) => {
      return app.i18n.t(`validation.${values._rule_}`, values);
    },
  });
}
