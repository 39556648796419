import { useRoute, useRouter } from "@nuxtjs/composition-api";

export function useNavigateToCallbackIfAny() {
  const route = useRoute();
  const router = useRouter();

  const queryKeys = ["returnTo", "callback-url"];

  return function navigateToCallbackIfAny(fallbackPath) {
    const queryKey = queryKeys.find((key) => route.value.query[key]);

    const nextPath = queryKey
      ? decodeURIComponent(route.value.query[queryKey])
      : fallbackPath;

    router.push(nextPath);
  };
}
