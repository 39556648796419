import Vue from "vue";
import VueGtm from "@gtm-support/vue2-gtm";

/**
 * This plugin provides a wrapper around @gtm-support/vue2-gtm in order to
 * enable the use of its built-in abstraction of Analytics events, as well as
 * extend it with an abstraction of custom data layer events based on its
 * official documentation and replicating its behavior.
 *
 * Debug mode is enabled only in non-production environments.
 *
 * For more information, see sample/sample.env.
 *
 * Always use this.$track instead of this.$gtm in components for consistency.
 *
 * Available methods:
 *  - this.$track.view – wrapper for this.$gtm.trackView;
 *  - this.$track.analyticsEvent – wrapper for this.$gtm.trackEvent;
 *  - this.$track.customEvent – wrapper for window.dataLayer.push.
 *
 * For more information, see:
 * https://github.com/gtm-support/vue-gtm.
 */
export default function ({ app }, inject) {
  const gtmContainerId = process.env.GOOGLE_TAG_MANAGER_CONTAINER_ID;

  Vue.use(VueGtm, {
    id: gtmContainerId ?? "GTM-XXXXXXX",
    enabled: !!gtmContainerId,
    debug: process.env.NODE_ENV !== "production",
    vueRouter: app.router,
  });

  const track = {
    view() {
      Vue.gtm.trackView(...arguments);
    },

    analyticsEvent() {
      Vue.gtm.trackEvent(...arguments);
    },

    /**
     * Custom data layer event wrapper, replicating the behavior of trackEvent.
     * Source: https://github.com/gtm-support/core/blob/33ed2424c61665129e2bf3d3daf0d708ef5b9050/src/gtm-support.ts#L169-L219
     * Version: 1.0.0
     */
    customEvent() {
      const isEnabled = Vue.gtm.enabled();
      const isDebug = Vue.gtm.debugEnabled();

      if (isDebug) {
        console.info(
          `[GTM-Support${
            isEnabled ? "" : "(disabled)"
          }]: Dispatching custom event`,
          ...arguments
        );
      }

      if (isEnabled) {
        window.dataLayer.push(...arguments);
      }
    },
  };

  inject("track", track);
}
