<template>
  <div>
    <slot name="label" :failed="failed">
      <p
        v-if="label"
        class="text-base font-bold"
        :class="{
          'text-error': failed,
        }"
      >
        {{ label }}
      </p>
      <input :value="value" class="hidden" />
    </slot>
    <button
      class="flex p-2 relative text-left w-full rounded bg-white border border-solid border-gray-300 hover:border-gray-400 focus-within:!border-primary focus-within:shadow-md"
      type="button"
      :class="{ '!bg-gray-100': _disabled, 'cursor-default': _disabled }"
      @click="dropdown"
    >
      <IconComponent
        :icon="icon"
        pack="iconPack"
        class="absolute top-1/2 -translate-y-1/2 left-0 ml-2 text-gray-300 placeholder-gray-300 w-6"
      />
      <span
        class="box-border w-full pr-9 truncate"
        :class="{
          '!pl-9': icon,
        }"
      >
        {{ shownValue }}
      </span>
      <IconComponent
        v-if="!clearable || (clearable && !value)"
        icon="angle-down"
        class="absolute top-1/2 -translate-y-1/2 right-0 mr-2 text-gray-300 placeholder-gray-300 w-6"
      />
      <IconComponent
        v-else-if="clearable && value"
        icon="times"
        class="absolute top-1/2 -translate-y-1/2 right-0 mr-2 text-gray-300 placeholder-gray-300 w-6"
        @click="clear"
      />
      <div
        v-show="open"
        class="absolute top-full left-0 right-0 bg-white rounded-md z-50 border border-gray-300 shadow-md"
      >
        <div ref="container" class="max-h-52 overflow-y-auto py-2">
          <div
            v-for="item in items"
            :key="item[valueKey]"
            :ref="item[valueKey]"
            class="max-md:py-2 md:py-1 pl-3 pr-2 cursor-pointer hover:bg-gray-100 text-sm text-black"
            @click.stop.prevent="selectItem(item)"
          >
            {{ item[labelKey] }}
          </div>
        </div>
      </div>
    </button>
    <p v-show="failed" class="text-sm text-error mt-1">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { inject } from "@nuxtjs/composition-api";
import IconComponent from "~/components/IconComponent.vue";

export default {
  components: { IconComponent },
  props: {
    value: {
      type: [Object, String, Number],
      required: false,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    labelKey: {
      type: String,
      default: "label",
    },
    icon: {
      type: String,
      default: "",
    },
    iconPack: {
      type: String,
      default: "fas",
    },
    valueKey: {
      type: String,
      default: "value",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    validationState: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  setup() {
    const { disabled: formDisabled } = inject("form", { disabled: false });
    return {
      formDisabled,
    };
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    _disabled() {
      return this.disabled || this.formDisabled;
    },
    selectedItem() {
      return this.items.find((item) => item[this.valueKey] === this.value);
    },
    shownValue() {
      if (this.selectedItem) {
        return this.selectedItem[this.labelKey];
      }
      return this.placeholder;
    },
    errorMessage() {
      return this.validationState?.$errors?.[0]?.$message;
    },
    failed() {
      return this.validationState?.$error;
    },
  },
  mounted() {
    document.addEventListener("click", this.closeOnOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeOnOutsideClick);
  },
  methods: {
    selectItem(item) {
      this.open = false;
      this.$emit("input", item[this.valueKey]);
      this.$emit("select", item);
    },
    dropdown() {
      if (this._disabled) return;
      this.open = !this.open;
    },
    closeOnOutsideClick(e) {
      if (!this.$el.contains(e.target)) {
        this.open = false;
      }
    },
    clear() {
      this.$emit("input", null);
    },
  },
};
</script>
