<template>
  <component
    :is="actualComponent"
    class="joszaki-button relative flex items-center justify-center gap-2"
    :class="[
      bgColor,
      textColor,
      borderColor,
      {
        'w-full': expanded,
        '!cursor-not-allowed': disabled,
        'border-solid border-2': outline,
        'text-white': !inverted,
        'p-1': size === 'sm',
        'p-3 !text-xl font-bold': size === 'lg',
        '!rounded-none': styling === 'squared',
        'inline-flex': inline,
      },
    ]"
    :title="title"
    :type="isSubmit ? 'submit' : 'button'"
    :href="href ?? to"
    :to="to"
    v-bind="$attrs"
    @click="onClick"
  >
    <IconComponent
      v-if="iconLeft && !loading && !disabled"
      :icon="iconLeft"
      :pack="iconLeftPack"
    />
    <IconComponent
      v-if="loading"
      icon="spinner"
      pack="fas"
      class="animate-spin"
    />
    <IconComponent v-if="disabled" icon="ban" pack="fas" />
    <slot />
    <IconComponent v-if="iconRight" :icon="iconRight" :pack="iconRightPack" />
  </component>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "info",
      validator: function (val) {
        return [
          "default",
          "info",
          "success",
          "warning",
          "danger",
          "error",
          "rating",
        ].includes(val);
      },
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
    styling: {
      type: String,
      default: "rounded",
      validator: (val) => ["rounded", "squared"].includes(val),
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
      validator: function (val) {
        return ["sm", "md", "lg"].includes(val);
      },
    },
    iconLeft: {
      type: String,
      default: "",
    },
    iconLeftPack: {
      type: String,
      default: "fa",
      validator: function (val) {
        return ["fa", "fab", "fas", "far"].includes(val);
      },
    },
    iconRight: {
      type: String,
      default: "",
    },
    iconRightPack: {
      type: String,
      default: "fa",
      validator: function (val) {
        return ["fa", "fab", "fas", "far"].includes(val);
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    /**
     * @example bg-info hover:bg-info-darker
     * @return {string}
     */
    bgColor() {
      if (this.inverted) {
        return `bg-white ${this.disabled ? "" : " hover:bg-white-darker"}`;
      }
      return `bg-${this.type}${this.disabled ? "-lightest" : ""} hover:bg-${
        this.type
      }${this.disabled ? "-lightest" : "-darker"}`;
    },
    /**
     * @example border-info hover:border-info-darker
     * @return {string}
     */
    borderColor() {
      return `border-${this.type}${
        this.disabled ? "-lightest" : ""
      } hover:border-${this.type}${this.disabled ? "-lighter" : "-darker"}`;
    },
    /**
     * @example text-info hover:text-info-darker
     * @return {string}
     */
    textColor() {
      if (this.inverted) {
        return `text-${this.type}${
          this.disabled ? "-lightest" : ` hover:text-${this.type}-darker`
        }`;
      }
      return "text-white hover:text-white-darker";
    },
    actualComponent() {
      if (this.href) {
        return "a";
      }

      if (this.to) {
        return "NuxtLink";
      }

      return "button";
    },
  },
  methods: {
    onClick(e) {
      if (this.isSubmit) {
        e.preventDefault();
      }
      if (this.disabled || this.loading) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if (this.href || this.to) {
        // e.preventDefault();
        e.stopPropagation();
        // location.href = this.href;
      }
      this.$emit("click", e);
    },
  },
};
</script>

<style>
.joszaki-button {
  @apply cursor-pointer  rounded-[4px] px-4 py-2 font-[Nunito];
}
</style>
