import { initUserOnClient } from "~/components/_refactored/user";

function extend(app, mixin) {
  if (!app.mixins) {
    app.mixins = [];
  }
  app.mixins.push(mixin);
}

export default function (context) {
  const { app } = context;
  extend(app, {
    async mounted() {
      app.$trackers.posthogLoadToolbar();
      app.$track.customEvent({
        event: "CIO.Init",
      });

      await initUserOnClient(context);
    },
  });
}
