<template>
  <div class="flex flex-col items-center gap-4">
    <p class="text-center">
      {{ $t("error500.p1") }}
    </p>
    <p class="text-center">
      {{ $t("error500.p2") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ErrorInternal",
};
</script>
