<template>
  <JoszakiAutocomplete
    ref="personAutocomplete"
    v-model="personValue"
    v-bind="$attrs"
    icon="user"
    :items="filteredPeople"
    :formatter="formatPerson"
    :placeholder="formatPerson(value)"
    @input="onInput"
    @focus="onFocus"
    @blur="onBlur"
    @select="onSelect"
  >
    <template #empty>
      {{ $t("conductor.common.noResults") }}
    </template>
  </JoszakiAutocomplete>
</template>

<script>
import { ref, useContext } from "@nuxtjs/composition-api";
import { debounce } from "~/helpers/debounce";
import FIND_PERSON from "~/components/_refactored/admin/transfers/findPerson.graphql";

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const formatPerson = (person) => {
      if (!person) {
        return "-";
      }
      return `${person.name} | ${person.email} | #${person.id}`;
    };

    const { $query } = useContext();
    const filteredPeople = ref([]);
    const personFilter = ref("");
    const personValue = ref(formatPerson(props.value));
    const focused = ref(false);

    const onInput = debounce(async function (search) {
      const resp = await $query(FIND_PERSON, { search });
      filteredPeople.value = resp.findPerson;
    }, 500);

    return {
      filteredPeople,
      personFilter,
      personValue,
      onInput,
      focused,
      formatPerson,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.personValue = this.formatPerson(newValue);
        }
      },
    },
  },
  methods: {
    onFocus() {
      this.focused = true;
      this.personValue = this.personFilter;
      this.$emit("focus");
    },
    onBlur() {
      this.focused = false;
      this.personFilter = this.personValue;
      this.personValue = this.formatPerson(this.value);
      this.$emit("blur");
    },
    onSelect(person) {
      this.personFilter = "";
      if (person) {
        this.$emit("select", person);
      } else {
        this.onBlur();
      }
    },
  },
};
</script>
