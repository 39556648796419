<template>
  <div>
    <JoszakiHeading :level="titleLevel">
      {{ title }}
    </JoszakiHeading>
    <JoszakiHeading v-if="$props.subtitle" :level="3">
      {{ subtitle }}
    </JoszakiHeading>
    <p v-if="$props.paragraph" class="font-semibold text-blog-primary">
      {{ paragraph }}
    </p>
    <!--  -->
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    titleLevel: {
      type: Number,
      default: 1,
    },
    subtitle: {
      type: String,
      default: null,
    },
    paragraph: {
      type: String,
      default: null,
    },
  },
};
</script>
