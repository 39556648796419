<template>
  <ValidationProvider
    ref="validation"
    v-slot="{ errors, invalid, failed }"
    class="JoszakiInput field"
    :class="{
      'has-addons': hasAddons,
    }"
    slim
    :rules="rules"
    :vid="vid"
  >
    <slot name="label" :failed="failed" :invalid="invalid">
      <div v-if="label" class="field-label is-normal !text-left">
        <label class="label">{{ label }}</label>
      </div>
    </slot>
    <p
      class="control"
      :class="{
        'has-icons-left': !!iconLeft,
        'has-icons-right': failed || !!iconRight,
      }"
    >
      <template v-if="mask">
        <input
          ref="input"
          v-mask="mask"
          class="input"
          :class="{
            'is-danger': failed,
          }"
          :type="type"
          :placeholder="placeholder"
          :value="value"
          :step="type === 'number' ? step ?? 1 : null"
          @input="onInput"
        />
      </template>
      <template v-else>
        <input
          ref="input"
          class="input"
          :class="{
            'is-danger': failed,
          }"
          :type="type"
          :placeholder="placeholder"
          :value="value"
          :disabled="disabled"
          :step="type === 'number' ? step ?? 1 : null"
          @input="onInput"
          @focusout="(event) => $emit('focusout', event)"
          @focusin="(event) => $emit('focusin', event)"
        />
      </template>
      <span v-if="iconLeft" class="icon is-small is-left">
        <IconComponent :pack="iconPackLeft" :icon="iconLeft" />
      </span>
      <span v-if="failed || !!iconRight" class="icon is-small is-right">
        <IconComponent
          :pack="iconPackRight"
          :icon="iconRight || 'exclamation-circle'"
          :class="{
            'text-error': failed && !iconRight,
          }"
        />
      </span>
    </p>
    <slot name="addons" />
    <p v-show="failed" class="text-sm text-error mt-1">
      {{ errors[0] }}
    </p>
  </ValidationProvider>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  props: {
    value: {
      type: [String, Boolean, Number],
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    hasAddons: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
      // TODO add more as needed
      validator: (val) =>
        ["text", "email", "number", "tel", "checkbox", "password"].includes(
          val
        ),
    },
    iconLeft: {
      type: String,
      default: "",
    },
    iconPackLeft: {
      type: String,
      default: "fas",
    },
    iconRight: {
      type: String,
      default: "",
    },
    iconPackRight: {
      type: String,
      default: "fas",
    },
    placeholder: {
      type: String,
      default: "",
    },
    mask: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    immediate: {
      type: Boolean,
      default: false,
      description: "Whether or not input should be validated immediately",
    },
    vid: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: null,
    },
  },
  computed: {
    hasIconRight() {
      return this.isFailed() || !!this.iconRight;
    },
  },
  mounted() {
    if (this.immediate) {
      this.$refs.validation.validate();
    }
  },
  methods: {
    onInput(event) {
      const value = event.target.value;
      this.$emit("input", value);
    },
    focus() {
      this.$refs.input.focus();
    },
    isFailed() {
      const validation = this.$refs.validation;
      return validation?.failed;
    },
  },
};
</script>
