<template>
  <button
    class="px-2 flex-initial"
    :class="{
      'bg-white text-primary hover:bg-whitesmoke border-solid border border-info':
        inverted,
      'bg-primary hover:bg-primary-light text-white': !inverted,
    }"
    @click="onClick"
  >
    <IconComponent
      v-if="loading"
      icon="spinner"
      pack="fas"
      class="animate-spin"
    />
    <template v-else>
      <IconComponent v-if="icon" :pack="iconPack" :icon="icon" />
    </template>
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: false,
      default: null,
    },
    iconPack: {
      type: String,
      required: false,
      default: "fas",
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      if (this.loading) {
        return;
      }
      this.$emit("click");
    },
  },
};
</script>
