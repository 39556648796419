export function useDataTableFilterOperators() {
  return {
    EQUALS: "EQUALS",
    NOT_EQUALS: "NOT_EQUALS",
    IN: "IN",
    NOT_IN: "NOT_IN",
    BETWEEN: "BETWEEN",
    LIKE: "LIKE",
    IS_NULL: "IS_NULL",
    NOT_NULL: "NOT_NULL",
  };
}