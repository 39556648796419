import Vue from "vue";
import { getTTFB, getFCP, getLCP, getFID, getCLS } from "web-vitals/base";

export default function ({ app }) {
  const queue = new Set();

  function addToQueue(metric) {
    queue.add(metric);
  }

  function flushQueue() {
    if (Vue.gtm.enabled() && queue.size > 0) {
      console.info("Flushing web vitals queue", [...queue]);

      app.$track.customEvent({
        event: "Web Vitals",
        metrics: Object.fromEntries(
          queue.map(({ id, name, value }) => [
            name,
            {
              id,
              value,
            },
          ])
        ),
      });

      queue.clear();
    }
  }

  getTTFB(addToQueue); // Time To First Byte (Loading)
  getFCP(addToQueue); // First Contentful Paint (Loading)
  getLCP(addToQueue); // Largest Contentful Paint (Loading)
  getFID(addToQueue); // First Input Delay (Interactivity)
  getCLS(addToQueue); // Cumulative Layout Shift (Visual stability)

  // Report all available metrics whenever the page is backgrounded or unloaded
  addEventListener("visibilitychange", () => {
    if (document.visibilityState === "hidden") {
      flushQueue();
    }
  });

  // Also flush the queue in the `pagehide` event, for Safari which does not
  // reliably fire the `visibilitychange` event when the page is unloaded
  addEventListener("pagehide", flushQueue);
}
