<template>
  <JoszakiButton v-bind="$attrs" class="relative">
    <input
      class="absolute opacity-0 top-0 right-0 left-0 bottom-0 cursor-pointer"
      type="file"
      :multiple="allowMultiple"
      :accept="accept"
      @change="changeHandler"
    />
    {{ label }}
  </JoszakiButton>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    accept: {
      type: String,
      default: "*",
    },
    allowMultiple: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    changeHandler(event) {
      const files = event.target.files;
      const hasLargeFile = Array.from(files)
        .map((file) => file.size)
        .some((size) => size > process.env.MAX_UPLOAD_SIZE_IN_MB * 1000 * 1000);

      if (hasLargeFile) {
        this.$joszaki.toast({
          message: this.$t("uploader.maxFileSize", {
            maxSize: process.env.MAX_UPLOAD_SIZE_IN_MB,
          }),
          type: "error",
        });
        return;
      }
      this.$emit("upload", files);
    },
  },
};
</script>
