<template>
  <ValidationProvider
    ref="validation"
    v-slot="{ errors, invalid }"
    class="field"
    slim
    :rules="rules"
    :vid="vid"
  >
    <div
      class="dropdown flex-col justify-center"
      :class="{
        'is-active': open,
      }"
      @click="dropdown"
    >
      <slot name="label">
        <div v-if="label" class="field-label is-normal !text-left">
          <label class="label">{{ label }}</label>
        </div>
        <input v-model="value" class="hidden" />
      </slot>
      <div class="dropdown-trigger">
        <button
          class="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
        >
          <span>{{ shownValue }}</span>
          <span class="icon is-small">
            <IconComponent icon="angle-down" />
          </span>
        </button>
      </div>
      <p v-show="invalid" class="text-sm text-error mt-1">
        {{ errors[0] }}
      </p>
      <div id="dropdown-menu" class="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <a
            v-for="item in items"
            :key="item[valueKey]"
            href="#"
            class="dropdown-item"
            @click.stop="selectItem(item)"
          >
            {{ item[labelKey] }}
          </a>
          <!-- <hr class="dropdown-divider"> -->
        </div>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import IconComponent from "~/components/IconComponent.vue";

export default {
  components: { IconComponent },
  props: {
    items: {
      type: Array,
      required: true,
    },
    labelKey: {
      type: String,
      default: "label",
    },
    valueKey: {
      type: String,
      default: "value",
    },
    placeholder: {
      type: String,
      default: "Choose an item",
    },
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    vid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      open: false,
      value: null,
    };
  },
  computed: {
    shownValue() {
      if (this.value) {
        return this.value[this.labelKey];
      }
      return this.placeholder;
    },
  },
  methods: {
    selectItem(item) {
      this.value = item;
      this.open = false;
      this.$emit("select", item);
    },
    dropdown() {
      this.open = !this.open;
    },
  },
};
</script>

<style></style>
