import { ToastProgrammatic as Toast } from "buefy";
import dayjs from "dayjs";

/** Cheap, simple ENUM-like enumeration */
const Availability = Object.freeze({
  ANYTIME: "ANYTIME",
  FEW_DAYS: "FEW_DAYS",
  FEW_WEEKS: "FEW_WEEKS",
  FEW_MONTHS: "FEW_MONTHS",
  NONE: "NONE",
});

/** Single Source of Truth Object
 * containing raw values about Mate availability categories
 */
const AvailabilityTable = Object.freeze({
  [Availability.ANYTIME]: {
    key: Availability.ANYTIME,
    trustDays: 3,
    daysToAdd: 0,
    maxDayDiff: 0,
    buefyType: "success",
  },
  [Availability.FEW_DAYS]: {
    key: Availability.FEW_DAYS,
    trustDays: 2,
    daysToAdd: 3,
    maxDayDiff: 3,
    buefyType: "success",
  },
  [Availability.FEW_WEEKS]: {
    key: Availability.FEW_WEEKS,
    trustDays: 5,
    daysToAdd: 14,
    maxDayDiff: 14,
    buefyType: "warning",
  },
  [Availability.FEW_MONTHS]: {
    key: Availability.FEW_MONTHS,
    trustDays: 20,
    daysToAdd: 30,
    maxDayDiff: 60,
    buefyType: "danger",
  },
});

const NONE = {
  key: Availability.NONE,
  buefyType: "",
};

export default function (context, inject) {
  const getAvailibility = (lastUnavailable) => {
    if (!lastUnavailable?.from || !lastUnavailable?.until) {
      return NONE;
    }
    const { from, until } = lastUnavailable;
    const dayDiff = dayjs(until).diff(dayjs(from), "day");
    if (!dayDiff) {
      return AvailabilityTable[Availability.ANYTIME];
    } else if (dayDiff <= AvailabilityTable[Availability.FEW_DAYS].maxDayDiff) {
      return AvailabilityTable[Availability.FEW_DAYS];
    } else if (
      dayDiff <= AvailabilityTable[Availability.FEW_WEEKS].maxDayDiff
    ) {
      return AvailabilityTable[Availability.FEW_WEEKS];
    } else if (
      dayDiff <= AvailabilityTable[Availability.FEW_MONTHS].maxDayDiff
    ) {
      return AvailabilityTable[Availability.FEW_MONTHS];
    }

    return NONE;
  };

  const localizedAvailibility = (lastUnavailable) => {
    if (!lastUnavailable) {
      return context.i18n.t(`mateAvailability.${Availability.NONE}`);
    }
    const availibility = getAvailibility(lastUnavailable);
    if (availibility !== NONE) {
      return context.i18n.t("mateAvailability.iAmAvailableNext", {
        when: context.i18n.t(`mateAvailability.${availibility.key}`),
      });
    }
    return context.i18n.t(`mateAvailability.${Availability.NONE}`);
  };

  const getAvailabilityFromDate = (date) => {
    return getAvailibility({
      from: dayjs(),
      until: date,
    });
  };

  const showAvailabilityChangeSuccesSnack = (code) => {
    const days = code.trustDays;

    const firstLine = context.i18n.t("mateAvailability.iAmAvailableNext", {
      when: context.i18n.t(`mateAvailability.${code.key}`),
    });
    const secondLine = `<span class="">${context.i18n.t(
      "mateAvailability.itWillBeShownFor",
      { days }
    )}</span>`;

    Toast.open({
      message: `${firstLine}<br>${secondLine}`,
      type: "is-success",
    });
  };

  inject("mateAvailabilityService", {
    getAvailibility,
    localizedAvailibility,
    getAvailabilityFromDate,
    table: AvailabilityTable,
    showAvailabilityChangeSuccesSnack,
  });
}
