<template>
  <JoszakiAutocomplete
    v-model="professionTaskFilter"
    v-bind="$attrs"
    :placeholder="placeholder ?? $t('professionTaskAutocomplete.placeholder')"
    :items="items"
    :formatter="(item) => item.name"
    v-on="$listeners"
  >
    <template #empty>
      <span>
        {{ $t("professionTaskAutocomplete.empty") }}
      </span>
    </template>
  </JoszakiAutocomplete>
</template>

<script>
import { ref, watch, computed } from "@nuxtjs/composition-api";
import { debounce } from "~/helpers/debounce";
import { useProfessionTaskQueries } from "~/composables/useProfessionTaskQueries";
import { useTextSearch } from "~/composables/useTextSearch";

export default {
  model: {
    prop: "value",
    event: "select",
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    professionId: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const professionTaskFilter = ref("");
    const { fetchProfessionTasks, fetchProfessionTasksByKeyword } =
      useProfessionTaskQueries();

    const optionsFilteredToOneProfession = ref(null);

    watch(
      () => props.professionId,
      async (professionId) => {
        if (professionId) {
          optionsFilteredToOneProfession.value = await fetchProfessionTasks({
            professionIds: [professionId],
          });
          // Clear if selected profession task is not in the list
          if (
            props.value &&
            !optionsFilteredToOneProfession.value.includes(props.value.id)
          ) {
            emit("select", null);
          }
        } else {
          optionsFilteredToOneProfession.value = null;
        }
      }
    );

    const searchQueryResult = ref([]);
    const searchByKeyword = debounce(async function (keyword) {
      searchQueryResult.value = await fetchProfessionTasksByKeyword(keyword);
    }, 500);

    watch(
      professionTaskFilter,
      (newValue) => {
        !props.professionId && searchByKeyword(newValue);
      },
      { immediate: true }
    );

    const professionTasks = computed(() => {
      if (!props.professionId) {
        return searchQueryResult.value ?? [];
      }
      return optionsFilteredToOneProfession.value ?? [];
    });

    const { results: items } = useTextSearch(
      professionTaskFilter,
      professionTasks
    );

    watch(
      () => props.value,
      (newValue) => {
        if (newValue) {
          professionTaskFilter.value = newValue.name;
        } else {
          professionTaskFilter.value = "";
        }
      },
      { immediate: true }
    );

    return {
      searchQueryResult,
      optionsFilteredToOneProfession,
      professionTaskFilter,
      items,
    };
  },
};
</script>
