var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4 border-l-4 border-solid font-bold",class:{
    'border-info bg-info/20 text-info': _vm.type === 'info',
    'border-success bg-success/20 text-success': _vm.type === 'success',
    'border-warning bg-warning/20 text-warning': _vm.type === 'warning',
    'border-danger bg-danger/20 text-danger': _vm.type === 'danger',
    'border-error bg-error/20 text-error': _vm.type === 'error',
  }},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.message)+" ")]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }