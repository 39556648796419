import { computed, reactive, watch } from "vue";
import { useContext } from "@nuxtjs/composition-api";
import { useVuelidate } from "@vuelidate/core";

const formData = reactive(initData());

function initData() {
  return {
    id: null,
    name: "",
    seoName: "",
  };
}

function getSubmitData() {
  return {
    ...formData,
  };
}

export function useProfessionGroup() {
  const {
    $validation: { rules },
    $mutate,
    $query,
    $helpers,
    $joszaki,
    i18n,
  } = useContext();

  const resetForm = () => {
    Object.assign(formData, initData());
  };

  watch(
    () => formData.name,
    (val) => {
      if (!formData.id) {
        formData.seoName = $helpers.stringToSlug(val);
      }
    }
  );

  const validations = computed(() => {
    return {
      name: {
        required: rules.required,
        minLength: rules.minLength(3),
        maxLength: rules.maxLength(255),
      },
      seoName: {
        required: rules.required,
        minLength: rules.minLength(3),
        maxLength: rules.maxLength(255),
        mustBeSlug: rules.mustBeSlug,
      },
    };
  });

  const v$ = useVuelidate(validations, formData, { $stopPropagation: true });

  watch(
    () => v$.value.seoName.$dirty,
    (val) => {
      if (formData.id && val) {
        $joszaki.alert({
          message: i18n.t("conductor.messages.seoNameChanged"),
          type: "warning",
        });
      }
    }
  );

  async function saveProfessionGroup() {
    try {
      if (formData.id !== null) {
        const UPDATE_PROFESSION_GROUP = await import(
          "./updateProfessionGroup.graphql"
        );
        await $mutate(UPDATE_PROFESSION_GROUP, {
          ...getSubmitData(),
        });
      } else {
        const CREATE_PROFESSION_GROUP = await import(
          "./createProfessionGroup.graphql"
        );
        const response = await $mutate(CREATE_PROFESSION_GROUP, {
          ...getSubmitData(),
        });
        formData.id = response.id;
      }
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function deleteProfessionGroupById(id) {
    try {
      const DELETE_PROFESSION_GROUP = await import(
        "./deleteProfessionGroupById.graphql"
      );
      await $mutate(DELETE_PROFESSION_GROUP, { id });
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getProfessionGroupById(id) {
    try {
      const GET_PROFESSION_GROUP = await import(
        "./getProfessionGroupById.graphql"
      );
      const { professionGroupById } = await $query(GET_PROFESSION_GROUP, {
        id,
      });
      Object.assign(formData, professionGroupById);
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getProfessionGroups(params) {
    try {
      const GET_PROFESSION_GROUPS = await import(
        "./professionGroupsDataTable.graphql"
      );
      const { professionGroupsDataTable } = await $query(
        GET_PROFESSION_GROUPS,
        params
      );
      return professionGroupsDataTable;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  return {
    formData,
    resetForm,
    v$,
    saveProfessionGroup,
    deleteProfessionGroupById,
    getProfessionGroupById,
    getProfessionGroups,
  };
}
