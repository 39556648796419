import { useContext } from "@nuxtjs/composition-api";
import { useAuthStore } from "../store";
import { useCheckMismatch } from "./useCheckMismatch";

export function useConfirmEmail() {
  const authStore = useAuthStore();

  const checkMismatch = useCheckMismatch();

  const { $mutate } = useContext();

  async function confirmEmailRequest(payload) {
    const CONFIRM_EMAIL_MUTATION = await import(
      "../mutations/confirmEmailMutation.graphql"
    );
    return $mutate(CONFIRM_EMAIL_MUTATION, payload);
  }

  /**
   * Confirm email
   * @param {Object} payload -
   * @param {string} payload.email
   * @param {string} payload.emailConfirmationCode
   */
  return async function confirmEmail(payload) {
    const resp = await confirmEmailRequest(payload);

    checkMismatch(resp.personId, resp.token);

    await authStore.loginWithToken(resp.token);
  };
}
