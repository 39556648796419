<template>
  <div class="flex flex-col items-center gap-4">
    <div class="text-center">
      {{ $t("error404.info.p1") }}
      {{ $t("error404.info.p2") }}
    </div>
    <NuxtLink
      to="/"
      class="rounded-[2rem] bg-primary text-white hover:text-white-darker px-6 py-2 text-xl"
    >
      {{ $t("error404.searchForProfessionals") }}
    </NuxtLink>
    <div class="text-center">
      <i18n path="error404.cta.text">
        <template #strongText>
          <strong>{{ $t("error404.cta.strongText") }}</strong>
        </template>
      </i18n>
    </div>
    <JoszakiButton type="error" size="lg" @click="onRequestTender">
      {{ $t("error404.cta.button") }}
    </JoszakiButton>
  </div>
</template>

<script>
import { useRequestTender } from "~/composables/requestTender";

export default {
  name: "ErrorNotFound",
  setup() {
    return {
      ...useRequestTender(),
    };
  },
  methods: {
    onRequestTender() {
      this.requestTender({
        source: "error-404",
      });
    },
  },
};
</script>
