import { decodeBase64 } from "~/helpers/base64";

export default function ({ redirect, app }) {
  // If the user is not authenticated
  if (app.$helpers.isHeroku()) {
    const hasToken = app.$cookie.get("heroku-token");
    if (hasToken) {
      const [username, password] = decodeBase64(hasToken).split("-");
      if (username === "guest" && password === "jojelszo") {
        return;
      }
    }
    // Does not work with 401 code for some reason
    return redirect("/heroku-login");
  }
}
