export const FEATURE_FLAGS = [
  {
    name: "new-tender-2",
    local: "control", // control, variant1, variant2
  },
  {
    name: "new-question-page",
    local: true,
  },
];
