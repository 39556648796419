import { useContext } from "@nuxtjs/composition-api";

export function useSubcountyActions () {
  const { $mutate, $query } = useContext();

  async function saveSubcounty (submitData) {
    try {
      if (submitData.id !== null) {
        const UPDATE_SUBCOUNTY = await import("./updateSubcounty.graphql");
        return await $mutate(UPDATE_SUBCOUNTY, submitData);
      } else {
        const CREATE_SUBCOUNTY = await import("./createSubcounty.graphql");
        return await $mutate(CREATE_SUBCOUNTY, submitData);
      }
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getSubcountyById (id) {
    try { 
      const GET_SUBCOUNTY = await import("./getSubcountyById.graphql");
      const { subcountyById } = await $query(GET_SUBCOUNTY, { id });
      return subcountyById;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getSubcounties(params) {
    try {
      const GET_SUBCOUNTYS = await import("./subcountiesDataTable.graphql");
      const { subcountiesDataTable } = await $query(GET_SUBCOUNTYS, params);
      return subcountiesDataTable;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  return {
    saveSubcounty,
    getSubcountyById,
    getSubcounties,
  };
}
